import React from "react";

const Paginator = ({...props}) => {
  return (
    <ul class="pagination pagination-primary">
      {
        props.currentPage > 1 &&
        <li class="page-item">
          <a class="page-link" onClick={props.onClick ? (e)=>props.onClick(e) : ""}>
            Previous
          </a>
        </li>
      }
      {
        props.nextPage > 0 &&
        <li class="page-item active">
          <a class="page-link" onClick={props.onClick ? (e)=>props.onClick(e) : ""}>
            Next
          </a>
        </li>
      }
    </ul>
  );
};

export default Paginator;
