import { authenticatedApi } from "./Http.service";

export const depositService = async (body) => {
    try {
        const {data} = await authenticatedApi().post('/v1/wallet/deposit', body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const addHash = async (body) => {
    try {
        const {data} = await authenticatedApi().post('/v1/wallet/hash', body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getTransactionsService = async (query) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/wallet/transaction?${query}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getTransactionByReferenceService = async (reference) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/wallet/transaction/${reference}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getPlansService = async () => {
    try {
        const {data} = await authenticatedApi().get(`/v1/plan`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const investService = async (body) => {
    try {
        const {data} = await authenticatedApi().post(`/v1/investment`, body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getInvestService = async () => {
    try {
        const {data} = await authenticatedApi().get(`/v1/investment`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const withdrawService = async (body) => {
    try {
        const {data} = await authenticatedApi().post('/v1/withdraw', body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getPaymentAddresses = async () => {
    try {
        const {data} = await authenticatedApi().get('/v1/wallet/addresses')
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}
