import { useQuery } from "@tanstack/react-query"
import { useAuthenticatedMutation, useAuthenticatedQuery } from "./query.hook"
import { changePassword, getUserReferrals, getUserService, getUserWalletService } from "../services/User.service"
import { reactQueryKeys } from "../config/query"
import { toast } from "react-toastify"
import { mutationError } from "../helpers/error.helper"


export const useGetUser  = () => useAuthenticatedQuery({
    queryFn: getUserService,
    queryKey: [reactQueryKeys.user]
})

export const useGetUserWallet  = () => useAuthenticatedQuery({
    queryFn: getUserWalletService,
    queryKey: [reactQueryKeys.wallet]
})

export const useGetUserAffiliate  = () => useAuthenticatedQuery({
    queryFn: getUserReferrals,
    queryKey: [reactQueryKeys.affiliates]
})

export const useChangePassword  = (body, schema) => useAuthenticatedMutation({
    mutationFn: async (e) => {
        e.preventDefault()

        const data = await schema.validate(body, {abortEarly: false, strict: true})
        const password = await changePassword(data)
        return password
    },
    onSuccess: (data) => {
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = window.location
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})
