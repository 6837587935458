import React from 'react'

const PrimaryButtonComponent = ({...props}) => {
  return (
        !props.isPending 
        ?
        props.onClick 
        ?
        <button onClick={props.onClick ? (e)=>props.onClick(e) : ""} type="submit" class="btn btn-primary btn-lg btn-block">
            {props.title || "--"}
        </button>
        :
        <button type="submit" class="btn btn-primary btn-lg btn-block">
            {props.title || "--"}
        </button>
        :
        <button disabled={true} class="btn btn-primary btn-lg btn-block">
            <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            </div>
        </button>
  )
}

export default PrimaryButtonComponent