import React, { useEffect, useState, useCallback } from 'react'
import * as Yup from "yup"
import { ToastContainer } from 'react-toastify'
import PrimaryButtonComponent from '../../components/PrimaryButton.component'
import DashContainerComponent from '../../components/DashContainer.component'
import { useCreateAdmin, useGetAllUsers } from '../../hooks/admin.hook'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import Paginator from '../../components/Paginator.component'
import debounce from 'lodash.debounce';
import { allUsersService } from '../../services/Admin.service'

const schema = Yup.object({
    firstname: Yup.string().required("first name is required").label("first name"),
    lastname: Yup.string().required("last name is required").label("last name"),
    username: Yup.string().required("username is required").label("username"),
    type: Yup.string().uppercase().label("type"),
    email: Yup.string().email("invalid email address").required("first name is required").label("email"),
    password: Yup.string().min(8, "password must be at least 8 characters").required("password is required").label("password")
})

const AllUsers = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [body, setBody] = useState({
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        password: "",
        type: ""
    })
    const [users, setUsers] = useState([])
    const [query, setQuery] = useState('');
    const [nextPage, setNextPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(4)

    const allUsersQuery = useGetAllUsers()

    const handleManual = async (e) => {
        setQuery(e.target.value)
        const data = await allUsersService(`name=${e.target.value}`);
        setUsers(data.data.users)
    }

    const createAdmin = useCreateAdmin(body, schema)

    async function getAllUsersPage(page) {
        console.log("page", page);
        const data = await allUsersService(`name=${query}&limit=${limit}&page=${page}`);
        setUsers(data.data.users)
        setNextPage(data.data.nextPage)
        setCurrentPage(data.data.currentPage)
        
        console.log("getAllUsersPage ", data.data, data.data.currentPage, currentPage);
    }

    useEffect(() => {
        async function getAllUsers() {
            const data = await allUsersService(`name=${query}&limit=${limit}`);
            setUsers(data.data.users)
            console.log("data.data ", data.data);
            setNextPage(data.data.nextPage)
            setCurrentPage(data.data.currentPage)
        }
        getAllUsers()
    }, [])
    

    return (
        <DashContainerComponent>
            <div className="content-body">
                <div class="container">

                    <div class="row">

                        {/* <div class="col-lg-8 col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h2>Add new admin</h2>
                                </div>
                                <div class="card-body">
                                    <form onSubmit={(e) => createAdmin.mutate(e)}>
                                        <div class="form-group">
                                            <label for="signup-fname" class="control-label sr-only">First name</label>
                                            <input onChange={(e) => setBody(prevState => ({...prevState, firstname: e.target.value}))} type="text" class="form-control" id="signup-fname" placeholder="first name" />
                                        </div>
                                        <div class="form-group">
                                            <label for="signup-lname" class="control-label sr-only">Last name</label>
                                            <input onChange={(e) => setBody(prevState => ({...prevState, lastname: e.target.value}))} type="text" class="form-control" id="signup-lname" placeholder="last name" />
                                        </div>
                                        <div class="form-group">
                                            <label for="signup-email" class="control-label sr-only">Email</label>
                                            <input onChange={(e) => setBody(prevState => ({...prevState, email: e.target.value}))} type="email" class="form-control" id="signup-email" placeholder="email" />
                                        </div>
                                        <div class="form-group">
                                            <label for="signup-username" class="control-label sr-only">Username</label>
                                            <input onChange={(e) => setBody(prevState => ({...prevState, username: e.target.value}))} type="text" class="form-control" id="signup-username" placeholder="username" />
                                        </div>
                                        <div class="form-group">
                                            <label for="signup-username" class="control-label sr-only">Role</label>
                                            <select class="form-control" onChange={(e) => setBody(prevState => ({...prevState, type: e.target.value}))}>
                                                <option>Select role</option>
                                                <option value="SUPPORT">SUPPORT</option>
                                                <option value="ADMIN">ADMIN</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="signup-password" class="control-label sr-only">Password</label>
                                            <input onChange={(e) => setBody(prevState => ({...prevState, password: e.target.value}))} type="password" class="form-control" id="signup-password" placeholder="Password" />
                                        </div>
                                        <PrimaryButtonComponent title="ADD ADMIN" isPending={isLoading} />
                                    </form>
                                </div>
                            </div>
                        </div> */}


                        <div className="col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="form-group">
                                        <div className="row">
                                            <div className="col-12">
                                                <input type="text" value={query} onChange={handleManual} class="form-control" placeholder="enter first name or last name" />
                                            </div>
                                            {/* <div className="col-6">
                                                <button className="btn btn-primary">Search</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{width: "60px"}}>#</th>
                                                    <th>First name</th>
                                                    <th>Last name</th>
                                                    <th>Username</th>
                                                    <th>Email</th>
                                                    <th>Role</th>
                                                    <th>Date</th>
                                                    <th>View</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    users && users.length > 0 && users.map(user => {
                                                            return (
                                                                <tr>
                                                                    <td>{user.id}</td>
                                                                    <td>{user.firstName}</td>
                                                                    <td>{user.lastName}</td>
                                                                    <td>{user.username}</td>
                                                                    <td>{user.email}</td>
                                                                    <td>{user.type}</td>
                                                                    <td>{dayjs(user.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                    <td>                                            
                                                                        <Link to={`/admin/all-users/${user.id}`} class="btn btn-info" title="Edit"><i class="fi fi-rs-eye"></i></Link>
                                                                        <Link to={`/admin/all-users/${user.id}`} class="btn btn-danger" title="Edit"><i class="fi fi-rs-trash"></i></Link>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                
                                            </tbody>
                                        </table>
                                        <Paginator nextPage={nextPage} currentPage={currentPage} onClick={() => getAllUsersPage(nextPage)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        <ToastContainer position="top-center" />
        </DashContainerComponent>
    )
}

export default AllUsers