import React from 'react'
import * as Yup from 'yup'
import { formatMoney } from '../helpers/service.helper'
import { useInvest } from '../hooks/wallet.hook'
import { ToastContainer } from 'react-toastify'
import PrimaryButtonComponent from './PrimaryButton.component'

const schema = Yup.object({
    amount: Yup.number().positive().min(50000, "amount mus be grater than $500").required("amount is required").label("amount"),
    planId: Yup.number().positive().required().label("plan id")
})
const PlanModalComponent = ({...props}) => {
    const investMutation = useInvest(props.body, schema)

    if (!props.show) return;
    return (
        <div class="modal fade show" style={{display: 'block'}} id="smallModal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="title" id="smallModalLabel">{props?.plan?.name || "--"}</h4>
                    </div>
                    <div class="modal-body"> You will be debited {formatMoney(props?.body?.amount || 0)} for this plan. Click continue to charge your account. </div>
                    <div class="modal-footer">
                        {/* <button onClick={(e) => investMutation.mutate(e)} class="btn btn-primary">CONTINUE</button> */}
                        <PrimaryButtonComponent onClick={(e)=> investMutation.mutate(e)} isPending={investMutation.isPending} title="CONTINUE" />
                        <button type="button" class="btn btn-danger" onClick={() => props.closeModal()}>CLOSE</button>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-center" />
        </div>
    )
}

export default PlanModalComponent