import { useMutation } from "@tanstack/react-query";
import { forgotPasswordService, loginService, registerService, resetPasswordService } from "../services/Auth.service";
import { toast } from 'react-toastify';
import { redirect } from "react-router-dom";
import { mutationError } from "../helpers/error.helper";


export const useRegister  = (body, schema) => useMutation({
    mutationFn: async (e) => {
        e.preventDefault()

        const data = await schema.validate(body, {abortEarly: false, strict: true})
        const register = await registerService(data)
        return register
    },
    onSuccess: (data) => {
        console.log(data);
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = "/login"
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})

export const useLogin  = (body, schema) => useMutation({
    mutationFn: async (e) => {
        e.preventDefault()

        const data = await schema.validate(body, {abortEarly: false, strict: true})
        const login = await loginService(data)
        return login
    },
    onSuccess: (data) => {
        console.log(data);
        const notify = () => toast.success(data.message)
        notify()

        localStorage.setItem("authToken", data.data.token)

        setTimeout(() => {
            return window.location.href = "/"
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})

export const useForgotPassword  = (body, schema) => useMutation({
    mutationFn: async (e) => {
        e.preventDefault()

        const data = await schema.validate(body, {abortEarly: false, strict: true})
        const forgotPassword = await forgotPasswordService(data)
        return forgotPassword
    },
    onSuccess: (data) => {
        console.log(data);
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = "/forgot-password"
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})

export const useResetPassword  = (body, schema) => useMutation({
    mutationFn: async (e) => {
        e.preventDefault()

        const data = await schema.validate(body, {abortEarly: false, strict: true})
        const resetPassword = await resetPasswordService(data)
        return resetPassword
    },
    onSuccess: (data) => {
        console.log(data);
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = "/login"
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})