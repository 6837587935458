import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from "../assets/logo/lumina.png"
import { logoutService } from '../services/Auth.service';
import { useGetUser } from '../hooks/user.hook'
import UserImg from "../assets/images/profile/3.png"
// import "../assets/css/main.css"

const NavigationComponent = () => {
  const [user, setUser] = useState(null);

  const userQuery = useGetUser()

  function themeToggle() {
    let element = document.body;
    element.classList.toggle("dark-theme");

    let theme = localStorage.getItem("theme");

    if (theme && theme === "dark-theme") {
        localStorage.setItem("theme", "");
    } else {
        localStorage.setItem("theme", "dark-theme");
    }
  }

  useEffect(() => {
    setUser(userQuery?.data?.data?.user)

  }, [userQuery])

  return (
    <>
      <div class="header">
        <div class="container">
          <div class="row">
              <div class="col-xxl-12">
                  <div class="header-content">
                      <div class="header-left">
                        <div class="brand-logo"><a class="mini-logo" href="index.html">
                          <img src={Logo} alt="" width="40" /></a>
                        </div>
                      </div>
                      <div class="header-right">
                          <div class="dark-light-toggle" onClick={() => themeToggle()}>
                              <span class="dark"><i class="fi fi-rr-eclipse-alt"></i></span>
                              <span class="light"><i class="fi fi-rr-eclipse-alt"></i></span>
                          </div>
                          {/* <div class="nav-item dropdown notification">
                              <div data-bs-toggle="dropdown">
                                  <div class="notify-bell icon-menu">
                                      <span><i class="fi fi-rs-bells"></i></span>
                                  </div>
                              </div>
                              <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-end">
                                  <h4>Recent Notification</h4>
                                  <div class="lists">
                                      <a class="" href="index-2.html#">
                                          <div class="d-flex align-items-center">
                                              <span class="me-3 icon success"><i class="fi fi-bs-check"></i></span>
                                              <div>
                                                  <p>Account created successfully</p>
                                                  <span>2024-11-04 12:00:23</span>
                                              </div>
                                          </div>
                                      </a>
                                      <a class="" href="index-2.html#">
                                          <div class="d-flex align-items-center">
                                              <span class="me-3 icon fail"><i class="fi fi-sr-cross-small"></i></span>
                                              <div>
                                                  <p>2FA verification failed</p>
                                                  <span>2024-11-04 12:00:23</span>
                                              </div>
                                          </div>
                                      </a>
                                      <a class="" href="index-2.html#">
                                          <div class="d-flex align-items-center">
                                              <span class="me-3 icon success"><i class="fi fi-bs-check"></i></span>
                                              <div>
                                                  <p>Device confirmation completed</p>
                                                  <span>2024-11-04 12:00:23</span>
                                              </div>
                                          </div>
                                      </a>
                                      <a class="" href="index-2.html#">
                                          <div class="d-flex align-items-center">
                                              <span class="me-3 icon pending"><i class="fi fi-rr-triangle-warning"></i></span>
                                              <div>
                                                  <p>Phone verification pending</p>
                                                  <span>2024-11-04 12:00:23</span>
                                              </div>
                                          </div>
                                      </a>
                                  </div>
                                  <div class="more">
                                      <a href="notifications.html">More<i class="fi fi-bs-angle-right"></i></a>
                                  </div>
                              </div>
                          </div> */}
                          <div class="dropdown profile_log dropdown">
                              <div data-bs-toggle="dropdown">
                                  <div class="user icon-menu active"><span><i class="fi fi-rr-user"></i></span></div>
                              </div>
                              <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu dropdown-menu-end">
                                  <div class="user-email">
                                      <div class="user">
                                          <span class="thumb"><img class="rounded-full" src={UserImg} alt="" /></span>
                                          <div class="user-info">
                                              <h5>{user?.firstName} {user?.lastName}</h5>
                                              <span>{user?.email}</span>
                                          </div>
                                      </div>
                                  </div>
                                  <a class="dropdown-item" href="/profile">
                                      <span><i class="fi fi-rr-user"></i></span>
                                      Profile
                                  </a>
                                  <a class="dropdown-item" href="/wallet">
                                      <span><i class="fi fi-rr-wallet"></i></span>
                                      Wallets
                                  </a>
                                  <a class="dropdown-item" href="/affiliates">
                                      <span><i class="fi fi-rs-link-alt"></i></span>
                                      Affiliates
                                  </a>
                                  <a class="dropdown-item logout" onClick={logoutService}>
                                      <span><i class="fi fi-bs-sign-out-alt"></i></span>
                                      Logout
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="sidebar">
          <div class="brand-logo"><a class="full-logo" href="/"><img src={Logo} alt="" width="30" /></a>
          </div>
          <div class="menu">
              <ul>
                  <li>
                    <span class="text-white">Users</span>
                    <a href="/">
                        <span>
                            <i class="fi fi-rr-dashboard"></i>
                        </span>
                    </a>
                  </li>
                  <li>
                    <span class="text-white">Wallets</span>
                    <a href="/wallet">
                        <span>
                            <i class="fi fi-rr-wallet"></i>
                        </span>
                        {/* <span class="nav-text">Wallets</span> */}
                    </a>
                  </li>
                  <li>
                    <span class="text-white">Plans</span>
                    <a href="/plans">
                        <span>
                            <i class="fi fi-rr-donate"></i>
                        </span>
                        {/* <span class="nav-text">Plans</span> */}
                    </a>
                  </li>
                  <li>
                    <span class="text-white">Stake</span>
                    <a href="/investments">
                      <span>
                        <i class="fi fi-sr-bullseye-arrow"></i>
                      </span>
                      {/* <span class="nav-text">Investments</span> */}
                    </a>
                  </li>
                  <li>
                    <span class="text-white">Affiliates</span>
                    <a href="/affiliates">
                      <span>
                        <i class="fi fi-rs-link-alt"></i>
                      </span>
                      {/* <span class="nav-text">Affiliates</span> */}
                    </a>
                  </li>
                  <li>
                    <span class="text-white">Settings</span>
                    <a href="/profile">
                      <span>
                        <i class="fi fi-rs-settings"></i>
                      </span>
                      {/* <span class="nav-text">Settings</span> */}
                    </a>
                  </li>
                  {
                      ((user?.type?.toLowerCase() === "superadmin") || (user?.type?.toLowerCase() === "admin")) && (
                          <>
                            <li>
                              <a href="/admin/all-users">
                                <span>
                                  <i class="fi fi-rs-users"></i>
                                </span>
                                <span class="nav-text">All Users</span>
                              </a>
                            </li>
                            <li>
                              <a href="/admin/transactions/deposit">
                                <span>
                                  <i class="fi fi-rs-bank"></i>
                                </span>
                                <span class="nav-text">All Deposit</span>
                              </a>
                            </li>
                            <li>
                              <a href="/admin/transactions/withdrawal">
                                <span>
                                  <i class="fi fi-rs-money"></i>
                                </span>
                                <span class="nav-text">All Withdrawals</span>
                              </a>
                            </li>
                            {/* <li><Link href="/admin/all-users"><i class="fa fa-users"></i><span>All users</span></Link></li> */}
                              {/* <li><Link to="/admin/settings"><i class="fa fa-cog"></i><span>Settings</span></Link></li> */}
                          </>
                      )
                  }
              </ul>
          </div>
      </div>
    </>
  )
}

export default NavigationComponent