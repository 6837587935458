import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

export const useSetLoaderState = () => {
  const queryClient = useQueryClient()
  queryClient.setQueryData('loader', true)
}

export const useUnSetLoaderState = () => {
  const queryClient = useQueryClient()
  queryClient.setQueryData('loader', false)
}

export const useGetLoaderState = () => {
  const queryClient = useQueryClient()
  return queryClient.getQueryData('loader')
}

