import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import DashContainerComponent from '../../components/DashContainer.component'
import { useParams } from 'react-router-dom';
import { useAddHash, useGetPaymentAddresses, useGetTransactionByReference } from '../../hooks/wallet.hook';
import dayjs from 'dayjs';
import QRCode from 'qrcode';
import { formatMoney } from '../../helpers/service.helper';
import PrimaryButtonComponent from '../../components/PrimaryButton.component';
import { ToastContainer } from 'react-toastify';

const schema = Yup.object({
    crypto: Yup.string().required().label("Crypto"),
    hash: Yup.string().required().label("Hash"),
    transactionId: Yup.number().positive().label("Transaction ID"),
})

const Invoice = () => {
    const [invoice, setInvoice] = useState(null)
    const [wallets, setWallets] = useState(null)
    const [url, setUrl] = useState("")
    const [wallet, setWallet] = useState("")

    const [crypto, setCrypto] = useState("")
    const [hash, setHash] = useState("")
    const [transactionId, setTransactionId] = useState("")

    const { reference } = useParams();
    const invoiceQuery = useGetTransactionByReference(reference)
    const walletQuery = useGetPaymentAddresses()

    const addHash = useAddHash({crypto, hash, transactionId}, schema)

    const createQRcode = async(key: string) => {
        const keySplit = key.split(":");
        const token = keySplit[0];
        const chain = keySplit[1];

        const wallet = wallets[chain.toLowerCase()]

        const amount = (invoice.amount + (invoice.amount*2)/100)/100

        const data = token === "tether" ? `${wallet}?amount=${amount}` : `${token}:${wallet}?amount=${invoice.amount/100}`;

        const url = await QRCode.toDataURL(data)

        setUrl(url)
        setWallet(wallet)
        setCrypto(token)
    }
    useEffect(() => {
      setInvoice(invoiceQuery.data?.data?.transaction);
      setWallets(walletQuery.data?.data?.wallets);
      setTransactionId(invoiceQuery.data?.data?.transaction.id);
    }, [invoiceQuery, walletQuery])

    const badges = {
        pending: "badge badge-warning",
        success: "badge badge-success",
        declined: "badge badge-danger"
    }
    
    return (
        <DashContainerComponent>
            <div class="content-body">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-xl-4">
                                        <div class="page-title-content">
                                            <h3>Invoice</h3>
                                            <p class="mb-2">Make payment now.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12">
                            <div class="row">
                                <div class="col-xl-7 col-lg-12 col-md-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">Invoice Details</h4>
                                        </div>
                                        <div class="card-body">
                                            {
                                                invoice && (
                                                    <>
                                                        <div class="table-responsive">
                                                            <table class="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><span class="text-primary">Reference</span></td>
                                                                        <td><span class="text-primary">{invoice?.reference || "--"}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Date</td>
                                                                        <td>{invoice && dayjs(invoice.createdAt).format('MMMM DD, YYYY')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Order Status</td>
                                                                        <td><span class={badges[invoice.status.toLowerCase()]+" mb-0"}>{invoice.status}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Amount</td>
                                                                        <td>{formatMoney(invoice.amount)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Fee</td>
                                                                        <td>{formatMoney(0)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Total</td>
                                                                        <td>{formatMoney(invoice.amount)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Hash</td>
                                                                        <td>{invoice.hash || "--"}</td>
                                                                    </tr>
                                                                    
                                                                    {/* <tr>
                                                                        <td>Vat</td>
                                                                        <td>
                                                                            <div class="text-danger">$0.25 Euro</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Sub Total</td>
                                                                        <td> $69.00 Euro</td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="row">
                                                            <div class="col-md-6">
                                                                <h5>Note</h5>
                                                                <p>This invoice will expire after 15 minutes, please endeavor to make payment before time elapses.</p>
                                                            </div>
                                                            <div class="col-md-6 text-right">
                                                                <p class="mb-0"><b>Sub-total:</b> {formatMoney(invoice.amount)}</p>
                                                                                                        
                                                                <h3 class="mb-0 m-t-10">{formatMoney(invoice.amount)}</h3>
                                                            </div>
                                                        </div>
                                                        {
                                                            invoice.status === "PENDING" &&
                                                            (
                                                                <div className='row'>
                                                                    {
                                                                        !invoice.hash && (
                                                                            <>
                                                                                <div className="col-lg-4">
                                                                                    <select className='form-control' onChange={(e) => createQRcode(e.target.value) }>
                                                                                        <option value="" selected>Select crypto...</option>
                                                                                        <option value="ethereum:erc20">ETH (ERC20)</option>
                                                                                        <option value="tether:erc20">USDT (ERC20)</option>
                                                                                        <option value="tether:trc20">USDT (TRC20)</option>
                                                                                        <option value="bitcoin:bitcoin">Bitcoin</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                <input className='form-control' onChange={(e) => setHash(e.target.value)} placeholder='enter your payment hash' />
                                                                                </div>
                                                                                <div className="col-lg-4">
                                                                                    {/* <button className='btn btn-primary' onClick={(e) => addHash.mutate(e)}>Submit</button> */}
                                                                                    <PrimaryButtonComponent title="Submit Hash" isPending={addHash.isPending} onClick={(e) => addHash.mutate(e)} />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        
                                                                    }
                                                                    <div className="col-lg-6">
                                                                        <img src={url} />
                                                                    <p>{wallet}</p>
                                                                </div>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                                
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div id="main-content" style={{marginTop: "100px"}}>
                <div class="container-fluid">
                    <div class="block-header">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12">
                                <h2>Invoice</h2>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="header">
                                    <h2>Single Invoice</h2>                          
                                </div>
                                <div class="body">
                                    <h3>Invoice Details : <strong class="text-primary">#{invoice?.reference || "--"}</strong></h3>
                                    {
                                        invoice &&
                                        (
                                            <div class="tab-content">
                                                <div role="tabpanel" class="tab-pane in active" id="details" aria-expanded="true">
                                                    <div class="row clearfix">
                                                        <div class="col-md-6 col-sm-6">
                                                            <address>
                                                                <strong>Lumina Trust</strong><br />
                                                            </address>
                                                        </div>
                                                        <div class="col-md-6 col-sm-6 text-right">
                                                            <p class="mb-0"><strong>Order Date: </strong> {dayjs(invoice.createdAt).format('MMMM DD, YYYY')}</p>
                                                            <p class="mb-0"><strong>Order Status: </strong> <span class={badges[invoice.status.toLowerCase()]+" mb-0"}>{invoice.status}</span></p>
                                                            <p><strong>Order ID: </strong> #{invoice.id}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row clearfix">
                                                        <div class="col-md-12">
                                                            <div class="table-responsive">
                                                                <table class="table table-hover">
                                                                    <thead class="thead-dark">
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th class="hidden-sm-down">Description</th>
                                                                            <th>Quantity</th>
                                                                            <th class="hidden-sm-down">Unit Cost</th>
                                                                            <th>Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td class="hidden-sm-down">This deposit is to credit your account to be able to purchase our plans.</td>
                                                                            <td>1</td>
                                                                            <td class="hidden-sm-down">{formatMoney(invoice.amount)}</td>
                                                                            <td>{formatMoney(invoice.amount)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div class="row clearfix">
                                                        <div class="col-md-6">
                                                            <h5>Note</h5>
                                                            <p>This invoice will expire after 15 minutes, please endeavor to make payment before time elapses.</p>
                                                        </div>
                                                        <div class="col-md-6 text-right">
                                                            <p class="mb-0"><b>Sub-total:</b> {formatMoney(invoice.amount)}</p>
                                                            <p class="mb-0">Processing Fee: 1%</p>                                        
                                                            <h3 class="mb-0 m-t-10">{formatMoney(invoice.amount + (invoice.amount/100))}</h3>
                                                        </div>                                    
                                                        <div class="hidden-print col-md-12 text-right">
                                                            <hr />
                                                            {
                                                                invoice.status === "PENDING" ?
                                                                invoice.invoiceId ?
                                                                <div className='row'>
                                                                    <div className="col-lg-6">
                                                                        <select className='form-control' onChange={(e) => createQRcode(e.target.value) }>
                                                                            <option value="" selected>Select crypto...</option>
                                                                            <option value="ethereum:erc20">ETH (ERC20)</option>
                                                                            <option value="tether:erc20">USDT (ERC20)</option>
                                                                            <option value="tether:trc20">USDT (TRC20)</option>
                                                                            <option value="bitcoin:bitcoin">Bitcoin</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <img src={url} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <button class="btn btn-primary">Create Another invoice</button>
                                                                :
                                                                <button class="btn btn-primary" disabled={true}>Pay Now</button>
                                                            }
                                                        </div>
                                                    </div>                                    
                                                </div>                                          
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  */}
            <ToastContainer position="top-center" />
        </DashContainerComponent>
    )
}

export default Invoice
