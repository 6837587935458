import { api } from "./Http.service"

export const registerService = async (body) => {
    try {
        const {data} = await api.post('/v1/auth/register', body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const loginService = async (body) => {
    try {
        const {data} = await api.post('/v1/auth/login', body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const logoutService = () => {
    localStorage.removeItem("authToken")
    window.location.href = "/login"
}

export const forgotPasswordService = async (body) => {
    try {
        const {data} = await api.post('/v1/auth/forgot-password', body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const resetPasswordService = async (body) => {
    try {
        const {data} = await api.post('/v1/auth/reset-password', body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}
