import React from 'react'
// import DashboardLoaderComponent from './DashboardLoader.component'

import NavigationComponent from './Navigation.component'

const DashContainerComponent = ({children}) => {
  return (
    <>
      <NavigationComponent />
      {children}
      <div class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="copyright">
                            <p>© Copyright {new Date().getFullYear()}
                                <a href="#"> {process.env.REACT_APP_COMPANY_NAME}</a> All Rights Reserved
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="footer-social">
                            <ul>
                                <li><a href="https://t.me/luminatrustofficial" target="_blank"><i class="fi fi-brands-telegram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default DashContainerComponent