import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'

import DashContainerComponent from '../../components/DashContainer.component'
import PrimaryButtonComponent from '../../components/PrimaryButton.component'

import { useGetUserWallet } from '../../hooks/user.hook'
import { formatMoney, formatNumber } from '../../helpers/service.helper'
import { useDeposit, useGetTransactions, useWithdrawRequest } from '../../hooks/wallet.hook'

import VisaImg from "../../assets/images/cc/visa.png";
import Logo from "../../assets/logo/lumina.png"


const schema = Yup.object({
  amount: Yup.number().positive().min(10000, "Amount must be greater than $100").required().label("Amount")
})

const withdrawalSchema = Yup.object({
  amount: Yup.number().positive().min(100).required().label("Amount"),
  wallet: Yup.string().required().label("Wallet"),
  crypto: Yup.string().min(3).required().label("Crypto")
})

const Wallet = () => {
  const [deposits, setDeposits] = useState([])
  const [withdrawals, setWithdrawals] = useState([])
  const [profits, setProfits] = useState([])

  const [body, setBody] = useState({
    amount: 0
  })

  const [withdrawBody, setWithdrawBody] = useState({
    amount: 0,
    crypto: "",
    wallet: ""
  })

  const walletQuery = useGetUserWallet()

  const depositQuery = useGetTransactions("category=DEPOSIT");
  const withdrawalQuery = useGetTransactions("category=WITHDRAWAL")
  const profitQuery = useGetTransactions("category=PROFIT")
  // const transactionQuery = await getTransactionsService(`reference=${reference}&type=PCREDIT`)
  const deposit = useDeposit(body, schema)

  const withdrawalMutation = useWithdrawRequest(withdrawBody, withdrawalSchema)


  useEffect(() => {
    setDeposits(depositQuery.data?.data?.transactions)
    setWithdrawals(withdrawalQuery.data?.data?.transactions)
    setProfits(profitQuery.data?.data?.transactions)

  }, [depositQuery, withdrawalQuery, profitQuery])

  return (
    <DashContainerComponent>
      <div class="content-body">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="page-title">
                        <div class="row align-items-center justify-content-between">
                            <div class="col-xl-4">
                                <div class="page-title-content">
                                    <h3>Wallet</h3>
                                    <p class="mb-2">Welcome to {process.env.REACT_APP_COMPANY_NAME}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wallet-tab">
                <div class="row g-0">
                    <div class="col-xl-3">
                        <div class="nav d-block">
                            <div class="row">
                                <div class="col-xl-12 col-md-6">
                                    <div class="wallet-nav active" data-bs-toggle="pill" data-bs-target="#a1">
                                        <div class="wallet-nav-icon">
                                            <span><i class="fi fi-rr-bank"></i></span>
                                        </div>
                                        <div class="wallet-nav-text">
                                            <h3>Deposit</h3>
                                            <p>{formatMoney(walletQuery.data?.data?.wallet?.balance || 0)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-md-6">
                                    <div class="wallet-nav" data-bs-toggle="pill" data-bs-target="#a2">
                                        <div class="wallet-nav-icon">
                                            <span><i class="fi fi-rr-credit-card"></i></span>
                                        </div>
                                        <div class="wallet-nav-text">
                                            <h3>Withdrawals</h3>
                                            <p>{formatMoney(walletQuery.data?.data?.wallet?.totalWithdrawal || 0)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-md-6">
                                    <div class="wallet-nav" data-bs-toggle="pill" data-bs-target="#a4">
                                        <div class="wallet-nav-icon">
                                            <span><i class="fi fi-rr-money-bill-wave-alt"></i></span>
                                        </div>
                                        <div class="wallet-nav-text">
                                            <h3>Profit</h3>
                                            <p>{formatMoney(walletQuery.data?.data?.wallet?.totalProfit || 0)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9">
                        <div class="tab-content wallet-tab-content">
                            <div class="tab-pane show active" id="a1">
                                <div class="wallet-tab-title">
                                    <h3>Deposit</h3>
                                </div>
                                <div class="row">
                                    <div class="col-xxl-6 col-xl-6 col-lg-6">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="wallet-total-balance">
                                                    <p class="mb-0">Total Balance</p>
                                                    <h2>{formatMoney(walletQuery.data?.data?.wallet?.balance || 0)}</h2>
                                                </div>
                                                <div class="funds-credit">
                                                    <p class="mb-0">Total Invoice</p>
                                                    <h5>{walletQuery.data?.data?.wallet?.totalInvoice}</h5>
                                                </div>
                                                <div class="funds-credit">
                                                    <p class="mb-0">Total Invested</p>
                                                    <h5>{formatMoney(walletQuery.data?.data?.wallet?.totalInvested || 0)}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xxl-6 col-xl-6 col-lg-6">
                                        <div class="credit-card visa">
                                            <div class="type-brand">
                                                <h4>Debit Card (Coming Soon)</h4>
                                                <img src={VisaImg} alt="" />
                                            </div>
                                            <div class="cc-number">
                                                <h6>1234</h6>
                                                <h6>5678</h6>
                                                <h6>****</h6>
                                                <h6>****</h6>
                                            </div>
                                            <div class="cc-holder-exp">
                                                <h5>Sandra Kleppmann</h5>
                                                <div class="exp"><span>EXP:</span><strong>12/28</strong></div>
                                            </div>
                                            <div class="cc-info">
                                                <div class="row justify-content-between align-items-center">
                                                    <div class="col-5">
                                                        <div class="d-flex">
                                                            <p class="me-3">Status</p>
                                                            <p><strong>Active</strong></p>
                                                        </div>
                                                        <div class="d-flex">
                                                            <p class="me-3">Currency</p>
                                                            <p><strong>USD</strong></p>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-7">
                                                        <div class="d-flex justify-content-between">
                                                            <div class="ms-3">
                                                                <p>Credit Limit</p>
                                                                <p><strong>20,000 USD</strong></p>
                                                            </div>
                                                            <div id="circle3"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="card">
                                        <div className="card-body">
                                          <form onSubmit={(e) => deposit.mutate(e)}>
                                            <div className="form-group">
                                              <label htmlFor="amount">Amount</label><br/>
                                              <span class="text-info">Input amount, create invoice and make payment</span>
                                              <input type="number" onChange={(e) => setBody(prevState => ({...prevState, amount: parseInt(e.target.value)}))} className="form-control" />
                                            </div>
                                            <div className="form-group mt-4">
                                              <PrimaryButtonComponent title="Create Invoice" isPending={deposit.isPending} />
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-xl-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">Deposit History</h4>
                                            </div>
                                            <div class="card-body">
                                                <div class="transaction-table">
                                                    <div class="table-responsive">
                                                        <table class="table mb-0 table-responsive-sm">
                                                            <thead>
                                                            <tr>
                                                              <th>#</th>
                                                              <th>Reference</th>
                                                              <th>Type</th>
                                                              <th>Category</th>                                    
                                                              <th>Status</th>
                                                              <th>Amount</th>
                                                              <th>Date</th>
                                                              <th>View</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                              deposits && deposits.length > 0 && deposits.map(deposit => {
                                                                  return (
                                                                    <tr>
                                                                        <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                                        <td>{deposit.reference}</td>
                                                                        <td>{deposit.type}</td>
                                                                        <td>{deposit.category}</td>
                                                                        <td>{deposit.status}</td>
                                                                        <td>{formatMoney(deposit.amount)}</td>
                                                                        <td>{dayjs(deposit.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                        <td>                                            
                                                                          <Link to={`/invoice/${deposit.reference}`} class="btn btn-info" title="Edit"><i class="fi fi-rr-eye"></i></Link>
                                                                        </td>
                                                                    </tr>
                                                                  )
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="a2">
                                <div class="wallet-tab-title">
                                    <h3>Withdrawals</h3>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="stat-widget-1">
                                            <h6>Total Withdrawal</h6>
                                            <h3>{formatMoney(walletQuery.data?.data?.wallet?.totalWithdrawal || 0)}</h3>
                                            {/* <p>
                                                <span class="text-success"><i class="fi fi-rr-arrow-trend-up"></i>2.47%</span>
                                                Last month <strong>$24,478</strong>
                                            </p> */}
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="stat-widget-1">
                                            <h6>Total Pending Withdrawals</h6>
                                            <h3>{formatMoney(walletQuery.data?.data?.wallet?.totalWithdrawal || 0)}</h3>
                                            {/* <p>
                                                <span class="text-success"><i class="fi fi-rr-arrow-trend-up"></i>2.47%</span>
                                                Last month <strong>$24,478</strong>
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="card">
                                        <div className="card-body">
                                        <form onSubmit={(e) => withdrawalMutation.mutate(e)}>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                                <input type="number" class="form-control" onChange={(e) => setWithdrawBody(prevState => ({...prevState, amount: parseInt(e.target.value)}))} />
                                            </div>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <label class="input-group-text" for="inputGroupSelect01">Crypto</label>
                                                </div>
                                                <select onChange={(e) => setWithdrawBody(prevState => ({...prevState, crypto: e.target.value}))} class="form-control" id="inputGroupSelect01">
                                                    <option selected>Choose...</option>
                                                    <option value="BTC">Bitcoin</option>
                                                    <option value="ETH">ETH (ERC20)</option>
                                                    <option value="USDT">USDT (ERC20)</option>
                                                    <option value="USDT">USDT (TRC20)</option>
                                                </select>
                                            </div>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">Wallet</span>
                                                </div>
                                                <input type="text" onChange={(e) => setWithdrawBody(prevState => ({...prevState, wallet: e.target.value}))} class="form-control" />
                                            </div>
                                            <PrimaryButtonComponent title="Withdraw" isPending={withdrawalMutation.isPending} />
                                        </form>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div class="col-xl-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">Withdrawal History</h4>
                                            </div>
                                            <div class="card-body">
                                                <div class="transaction-table">
                                                    <div class="table-responsive">
                                                        <table class="table mb-0 table-responsive-sm">
                                                            <thead>
                                                              <tr>
                                                                <th>#</th>
                                                                <th>Reference</th>
                                                                <th>Type</th>
                                                                <th>Category</th>                                    
                                                                <th>Status</th>
                                                                <th>Amount</th>
                                                                <th>Date</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {
                                                                withdrawals && withdrawals.length > 0 && withdrawals.map(withdrawal => {
                                                                    return (
                                                                      <tr>
                                                                          <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                                          <td>{withdrawal.reference}</td>
                                                                          <td>{withdrawal.type}</td>
                                                                          <td>{withdrawal.category}</td>
                                                                          <td>{withdrawal.status}</td>
                                                                          <td>{formatMoney(withdrawal.amount)}</td>
                                                                          <td>{dayjs(withdrawal.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                      </tr>
                                                                    )
                                                                  })
                                                              }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="a4">
                                <div class="wallet-tab-title">
                                    <h3>Profit</h3>
                                </div>
                                <div class="row">
                                    <div class="col-xxl-6 col-xl-6 col-lg-6">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="wallet-total-balance">
                                                    <p class="mb-0">Total Profit</p>
                                                    <h2>{formatMoney(walletQuery.data?.data?.wallet?.totalProfit || 0)}</h2>
                                                </div>
                                                <div class="funds-credit">
                                                    <p class="mb-0">Current Balance</p>
                                                    <h5>{formatMoney(walletQuery.data?.data?.wallet?.balance || 0)}</h5>
                                                </div>
                                                <div class="funds-credit">
                                                    <p class="mb-0">Total Withdrawn</p>
                                                    <h5>{formatMoney(walletQuery.data?.data?.wallet?.totalWithdrawal || 0)}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">Transaction History</h4>
                                            </div>
                                            <div class="card-body">
                                                <div class="transaction-table">
                                                    <div class="table-responsive">
                                                        <table class="table mb-0 table-responsive-sm">
                                                            <thead>
                                                              <tr>
                                                                <th>#</th>
                                                                <th>Reference</th>
                                                                <th>Type</th>
                                                                <th>Category</th>                                    
                                                                <th>Status</th>
                                                                <th>Amount</th>
                                                                <th>Date</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {
                                                                profits && profits.length > 0 && profits.map(profit => {
                                                                    return (
                                                                      <tr>
                                                                          <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                                          <td>{profit.reference}</td>
                                                                          <td>{profit.type}</td>
                                                                          <td>{profit.category}</td>
                                                                          <td>{profit.status}</td>
                                                                          <td>{formatMoney(profit.amount)}</td>
                                                                          <td>{dayjs(profit.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                      </tr>
                                                                    )
                                                                  })
                                                              }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </DashContainerComponent>
  )
}

export default Wallet
