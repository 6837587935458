import { authenticatedApi } from "./Http.service";

export const getUserService = async () => {
    try {
        const {data} = await authenticatedApi().get('/v1/user')
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getUserWalletService = async () => {
    try {
        const {data} = await authenticatedApi().get('/v1/wallet')
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getUserReferrals = async () => {
    try {
        const {data} = await authenticatedApi().get('/v1/user/referrals')
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getUserReferralsByLevel = async (userId) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/user/referrals/level/${userId}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const changePassword = async (body) => {
    try {
        const {data} = await authenticatedApi().post('/v1/user/change-password', body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}
