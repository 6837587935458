import React, { useEffect, useState } from 'react'
import DashContainerComponent from '../../components/DashContainer.component'
import { useGetInvestments, useGetTransactions } from '../../hooks/wallet.hook'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { getTransactionsService } from '../../services/wallet.service'
import { formatMoney } from '../../helpers/service.helper'

const Investments = () => {
    const [investments, setInvestments] = useState([])
    const [transactions, setTransactions] = useState({})

    const investmentQuery = useGetInvestments();

    const  getInvestmentTransactions = async(reference) => {
        try {
            const transactionQuery = await getTransactionsService(`reference=${reference}&type=PCREDIT`)
            return transactionQuery.data;
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
    setInvestments(investmentQuery.data?.data?.investments)
        
    }, [investmentQuery])
      
    useEffect(() => {
        async function fetchTrans() {
            if (investments && investments.length > 0) {
                for (const investment of investments) {
                    const trans = await getInvestmentTransactions(investment.reference)
                    
                    setTransactions(prevState => ({...prevState, [investment.reference]: trans.transactions}))
                }
            }
        }
        fetchTrans()
    }, [investments]);

    

    return (
        <DashContainerComponent>
            <div class="content-body">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-xl-4">
                                        <div class="page-title-content">
                                            <h3>Investments</h3>
                                            <p class="mb-2">All investments</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="budgets-tab">
                        <div class="row g-0">
                            <div class="col-xl-3">
                                <div class="nav d-block">
                                    <div class="row">
                                        {
                                            investments && investments.length > 0 && investments.map((investment, index) => (
                                                <div class="col-xl-12 col-md-6">
                                                    <div class="budgets-nav" data-bs-toggle="pill" data-bs-target={"#a"+index}>
                                                        <div class="budgets-nav-icon">
                                                            <span><i class="fi fi-rr-carrot"></i></span>
                                                        </div>
                                                        <div class="budgets-nav-text">
                                                            <h3>Investment {index+1}</h3>
                                                            <p>{formatMoney(investment.price)}</p>
                                                        </div>
                                                        <span class="show-time">{investment.percentage}%</span>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div class="add-budgets-link">
                                    <h5 class="mb-0">Invest Now</h5>
                                    <Link to="/plans">
                                        <i class="fi fi-rr-square-plus"></i>
                                    </Link>
                                </div>
                            </div>
                            <div class="col-xl-9">
                                <div class="tab-content budgets-tab-content">
                                    {
                                        investments && investments.length > 0 && investments.map((investment, index) => (
                                            <div class="tab-pane" id={"a"+index}>
                                                <div class="budgets-tab-title">
                                                    <h3>Investment {index+1}</h3>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-12">
                                                        <div class="card">
                                                            <div class="card-body">
                                                                <div class="d-flex justify-content-between">
                                                                    <div>
                                                                        <span>Profit</span>
                                                                        <h3>{formatMoney(investment.profit)}</h3>
                                                                    </div>
                                                                    <div class="text-end">
                                                                        <span>Top Earner (The highest profit on a single investment on {process.env.REACT_APP_COMPANY_NAME})</span>
                                                                        <h3>{formatMoney(1000000000)}</h3>
                                                                    </div>
                                                                </div>
                                                                <div class="progress">
                                                                    <div class="progress-bar" style={{width: ((100*investment.profit)/10000000)+"%"}} role="progressbar"></div>
                                                                </div>
                                                                <div class="d-flex justify-content-between mt-2">
                                                                    <span>{(100*investment.profit)/10000000}%</span>
                                                                    <span>100%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xxl-12">
                                                        <div class="card">
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                                        <div class="budget-widget">
                                                                            <p>Amount Invested</p>
                                                                            <h3>{formatMoney(investment.price)}</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                                        <div class="budget-widget">
                                                                            <p>Profit</p>
                                                                            <h3>{formatMoney(investment.profit)}</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                                        <div class="budget-widget">
                                                                            <p>Daily ROI</p>
                                                                            <h3>{investment.percentage}%</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                                                        <div class="budget-widget">
                                                                            <p>Date</p>
                                                                            <h3>{dayjs(investment.createdAt).format("DD/MM/YYYY hh:mm a")}</h3>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-12">
                                                        <div class="card">
                                                            <div class="card-header">
                                                                <h4 class="card-title">Investment Profits </h4>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="transaction-table">
                                                                    <div class="table-responsive">
                                                                        <table class="table mb-0 table-responsive-sm">
                                                                            <thead>
                                                                            <tr>
                                                                                <th>Reference</th>
                                                                                <th>Category</th>                                    
                                                                                <th>Status</th>
                                                                                <th>Amount</th>
                                                                                <th>Date</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {
                                                                                transactions && transactions[investment.reference] && transactions[investment.reference].length > 0 && transactions[investment.reference].map(transaction => {
                                                                                    return (
                                                                                            <tr>
                                                                                                <td>{transaction.reference}</td>
                                                                                                <td>{transaction.category}</td>
                                                                                                <td>{transaction.status}</td>
                                                                                                <td>{formatMoney(transaction.amount)}</td>
                                                                                                <td>{dayjs(transaction.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                            }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div id="main-content" style={{marginTop: "100px"}}>
                <div class="container-fluid">

                    <div class="row clearfix">

                        {
                            investments && investments.length > 0 && investments.map(investment =>(
                                
                                <div class="col-lg-4 col-md-12">
                                    <div class="card">
                                        <div class="body">
                                            <div class="new_timeline">
                                                <div class="header">
                                                    <div class="color-overlay">
                                                        <div class="day-number">{dayjs(investment.createdAt).format("D")}</div>
                                                        <div class="date-right">
                                                            <div class="day-name">{dayjs(investment.createdAt).format("dddd")}</div>
                                                            <div class="month">{dayjs(investment.createdAt).format("MMMM YYYY")}</div>
                                                        </div>
                                                    </div>                                
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div class="bullet pink"></div>
                                                        <div class="time">{dayjs(investment.createdAt).format("HH a")}</div>
                                                        <div class="desc">
                                                            <h3>Amount</h3>
                                                            <h4>{formatMoney(investment.price || 0)}</h4>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="bullet pink"></div>
                                                        <div class="time">{dayjs(investment.createdAt).format("HH a")}</div>
                                                        <div class="desc">
                                                            <h3>Total Profit</h3>
                                                            <h4>{formatMoney(investment.profit)}</h4>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="bullet pink"></div>
                                                        <div class="time">{dayjs(investment.createdAt).format("HH a")}</div>
                                                        <div class="desc">
                                                            <h3>Is Running</h3>
                                                            <h4>{investment.isRunning.toString()}</h4>
                                                        </div>
                                                    </li>
                                                    {
                                                        transactions && transactions[investment.reference] && transactions[investment.reference].length > 0 && transactions[investment.reference].map(trans => (
                                                            <li>
                                                                <div class="bullet pink"></div>
                                                                <div class="time">{dayjs(trans.createdAt).format("DD/MM/YY HH:mm a")}</div>
                                                                <div class="desc">
                                                                    <h3>Profit Amount</h3>
                                                                    <h4>{formatMoney(trans.amount || 0)}</h4>
                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                
                                            </div>
                                            <br/>
                                        </div>
                                    </div>
                                </div>

                            ))
                        }

                    </div>
                </div>
            </div> */}
        </DashContainerComponent>
    )
}

export default Investments
