import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { useGetUser, useGetUserWallet } from '../../hooks/user.hook'
import { formatMoney, formatNumber } from '../../helpers/service.helper'
import { useGetTransactions } from '../../hooks/wallet.hook'
import { badgesOptions } from '../../config/config'
import Logo from "../../assets/logo/lumina.png"

import DashContainerComponent from '../../components/DashContainer.component'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

const Index = () => {
  const [transactions, setTransactions] = useState([])
  const [user, setUser] = useState(null)

  const userQuery = useGetUser()
  const walletQuery = useGetUserWallet()
  const transactionsQuery = useGetTransactions("")

  useEffect(() => {
    setTransactions(transactionsQuery.data?.data?.transactions)
    setUser(userQuery?.data?.data?.user)

  }, [transactionsQuery, userQuery])
  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100
    }
  ];
  
  return (
    <DashContainerComponent>
      <div class="content-body">
          <div class="container">
              <div class="row">
                  <div class="col-12">
                      <div class="page-title">
                          <div class="row align-items-center justify-content-between">
                              <div class="col-xl-4">
                                  <div class="page-title-content">
                                      <h3>Dashboard</h3>
                                      <p class="mb-2">Welcome to {process.env.REACT_APP_COMPANY_NAME}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                      <div class="stat-widget-1">
                          <h6>Total Balance</h6>
                          <h3>{formatMoney(walletQuery.data?.data?.wallet?.balance || 0)}</h3>
                          <p>
                              {/* <span class="text-success">DEPOSIT</span> */}
                              <a className="btn btn-primary btn-lg btn-block" href="/wallet">DEPOSIT</a>
                          </p>
                      </div>
                  </div>
                  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                      <div class="stat-widget-1">
                          <h6>Total Profit</h6>
                          <h3>{formatMoney(walletQuery.data?.data?.wallet?.totalProfit || 0)}</h3>
                          {/* <p>
                              <span class="text-success"><i class="fi fi-rr-arrow-trend-up"></i>2.47%</span>
                              Last month <strong>$24,478</strong>
                          </p> */}
                      </div>
                  </div>
                  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                      <div class="stat-widget-1">
                          <h6>Total Withdrawals</h6>
                          <h3>{formatMoney(walletQuery.data?.data?.wallet?.totalWithdrawal || 0)}</h3>
                          {/* <p>
                              <span class="text-danger"><i class="fi fi-rr-arrow-trend-down"></i>2.47%</span>
                              Last month <strong>$24,478</strong>
                          </p> */}
                      </div>
                  </div>
                  <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                      <div class="stat-widget-1">
                          <h6>Total Referrals</h6>
                          <h3>{user ? user.totalReferrals : 0}</h3>
                          {/* <p>
                              <span class="text-success"><i class="fi fi-rr-arrow-trend-up"></i>2.47%</span>
                              Last month <strong>$24,478</strong>
                          </p> */}
                      </div>
                  </div>
              </div>
              <div class="row">
                  {/* <div class="col-xxl-12 col-xl-12 col-lg-12">
                      <div class="card">
                          <div class="card-header balance-trend">
                              <h4 class="card-title">Balace Trends <br /> <span>{formatMoney(walletQuery.data?.data?.wallet?.balance || 0)}</span></h4>
                              <div class="trend-stats">
                                  <p class="mb-0">Last Month</p>
                                  <span class="text-success">
                                      <i class="fi fi-rr-arrow-trend-up"></i>
                                      12.25%
                                  </span>
                              </div>
                          </div>
                          <div class="card-body">
                              <AreaChart
                                style={{width: "100%", height: 400}}
                                width={800}
                                height={400}
                                data={data}
                                margin={{
                                  top: 10,
                                  right: 30,
                                  left: 0,
                                  bottom: 0
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Area dataKey="uv" fill="#2f2cd8" />
                              </AreaChart>
                          </div>
                      </div>
                  </div> */}
                  {/* <div class=" col-xxl-4 col-xl-4 col-lg-6 col-md-12">
                      <div class="card">
                          <div class="card-header">
                              <h4 class="card-title">Monthly Expenses Breakdown</h4>
                          </div>
                          <div class="card-body">
                              <div class="progress-stacked">
                                  <div class="progress" style={{width: "38%"}}>
                                      <div class="progress-bar bg-orange-500"></div>
                                  </div>
                                  <div class="progress" style={{width: "22%"}}>
                                      <div class="progress-bar bg-amber-500"></div>
                                  </div>
                                  <div class="progress" style={{width: "12%"}}>
                                      <div class="progress-bar bg-yellow-500"></div>
                                  </div>
                                  <div class="progress" style={{width: "9%"}}>
                                      <div class="progress-bar bg-lime-500"></div>
                                  </div>
                                  <div class="progress" style={{width: "8%"}}>
                                      <div class="progress-bar bg-green-500"></div>
                                  </div>
                                  <div class="progress" style={{width: "6%"}}>
                                      <div class="progress-bar bg-cyan-500"></div>
                                  </div>
                                  <div class="progress" style={{width: "5%"}}>
                                      <div class="progress-bar bg-stone-500"></div>
                                  </div>
                              </div>
                              <div class="list-1 mt-3">
                                  <ul>
                                      <li>
                                          <p class="mb-0"><i class="fi fi-ss-circle text-orange-500"></i>Food</p>
                                          <h5 class="mb-0"><span>$1200</span>38%</h5>
                                      </li>
                                      <li>
                                          <p class="mb-0"><i class="fi fi-ss-circle text-amber-500"></i>Transport</p>
                                          <h5 class="mb-0"><span>$1200</span>38%</h5>
                                      </li>
                                      <li>
                                          <p class="mb-0"><i class="fi fi-ss-circle text-yellow-500"></i>Healthcare</p>
                                          <h5 class="mb-0"><span>$1200</span>38%</h5>
                                      </li>
                                      <li>
                                          <p class="mb-0"><i class="fi fi-ss-circle text-lime-500"></i>Education</p>
                                          <h5 class="mb-0"><span>$1200</span>38%</h5>
                                      </li>
                                      <li>
                                          <p class="mb-0"><i class="fi fi-ss-circle text-green-500"></i>Clothes</p>
                                          <h5 class="mb-0"><span>$1200</span>38%</h5>
                                      </li>
                                      <li>
                                          <p class="mb-0"><i class="fi fi-ss-circle text-cyan-500"></i>Pets</p>
                                          <h5 class="mb-0"><span>$1200</span>38%</h5>
                                      </li>
                                      <li>
                                          <p class="mb-0"><i class="fi fi-ss-circle text-stone-500"></i>Entertainment</p>
                                          <h5 class="mb-0"><span>$1200</span>38%</h5>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div> */}
                  <div class="col-xl-12">
                      <div class="card">
                          <div class="card-header">
                              <h4 class="card-title">Transaction History</h4>
                          </div>
                          <div class="card-body">
                              <div class="transaction-table">
                                  <div class="table-responsive">
                                      <table class="table mb-0 table-responsive-sm">
                                          <thead>
                                              <tr>
                                                <th>#</th>
                                                <th>Reference</th>
                                                <th>Type</th>
                                                <th>Category</th>                                    
                                                <th>Status</th>
                                                <th>Amount</th>
                                                <th>Date</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              transactions && transactions.length > 0 && transactions.map(transaction => {
                                                  return (
                                                    <tr>
                                                        <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                        <td>{transaction.reference}</td>
                                                        <td>{transaction.type}</td>
                                                        <td>{transaction.category}</td>
                                                        <td>{transaction.status}</td>
                                                        <td>{formatMoney(transaction.amount)}</td>
                                                        <td>{dayjs(transaction.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                    </tr>
                                                  )
                                                })
                                            }
                                              {/* <tr>
                                                  <td>
                                                      <span class="table-category-icon"><i class="bg-emerald-500 fi fi-rr-barber-shop"></i>
                                                          Beauty</span>
                                                  </td>
                                                  <td>
                                                      12.12.2023
                                                  </td>
                                                  <td>
                                                      Grocery Items and Beverage soft drinks
                                                  </td>
                                                  <td>
                                                      -32.20
                                                  </td>
                                                  <td>USD</td>
                                              </tr> */}
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </DashContainerComponent>
  )
}

export default Index