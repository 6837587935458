import "./assets/css/style.css";
import "./assets/vendor/toastr/toastr.min.css";

import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Login from './pages/auth/login';
import Index from './pages/dashboard/Index';
import Register from './pages/auth/Register';
import Invoice from './pages/dashboard/Invoice';
import Investments from './pages/dashboard/Investments';

import Plans from './pages/dashboard/Plans';
import { getAuthToken } from './helpers/service.helper';
import ForgotPassword from './pages/auth/ForgotPassword';
import Profile from './pages/dashboard/Profile';
import AllUsers from './pages/admin/AllUsers';
import ResetPassword from './pages/auth/ResetPassword';
import ViewUser from './pages/admin/ViewUser';
import Wallet from "./pages/dashboard/Wallet";
import Affiliate from "./pages/dashboard/Affiliate";
import AllTransactions from "./pages/admin/AllTransactions";

function App() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute> <Index /> </ProtectedRoute>} />
      <Route path="/wallet" element={<ProtectedRoute> <Wallet /> </ProtectedRoute>} />
      <Route path="/investments" element={<ProtectedRoute> <Investments /> </ProtectedRoute>} />
      <Route path="/affiliates" element={<ProtectedRoute> <Affiliate /> </ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute> <Profile /> </ProtectedRoute>} />
      <Route path="/plans" element={<ProtectedRoute> <Plans /> </ProtectedRoute>} />
      <Route path="/invoice/:reference" element={<ProtectedRoute> <Invoice /> </ProtectedRoute>} />

      <Route path="/admin/all-users" element={<ProtectedRoute> <AllUsers /> </ProtectedRoute>} />
      <Route path="/admin/all-users/:userId/" element={<ProtectedRoute> <ViewUser /> </ProtectedRoute>} />
      <Route path="/admin/transactions/:category" element={<ProtectedRoute> <AllTransactions /> </ProtectedRoute>} />
      

      {/* <Route path="/deposit" element={<ProtectedRoute> <Deposit /> </ProtectedRoute>} />
      <Route path="/invoice/:reference" element={<ProtectedRoute> <Invoice /> </ProtectedRoute>} />
      <Route path="/withdrawal" element={<ProtectedRoute> <Withdrawal /> </ProtectedRoute>} />
      <Route path="/plans" element={<ProtectedRoute> <Plans /> </ProtectedRoute>} />
      <Route path="/investments" element={<ProtectedRoute> <Investments /> </ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute> <Profile /> </ProtectedRoute>} />

      <Route path="/admin/all-users" element={<ProtectedRoute> <AllUsers /> </ProtectedRoute>} />
      <Route path="/admin/:userId/transactions" element={<ProtectedRoute> <AllTransactions /> </ProtectedRoute>} />
      <Route path="/admin/all-users/:userId/" element={<ProtectedRoute> <ViewUser /> </ProtectedRoute>} />
      <Route path="/admin/:userId/withdrawals" element={<ProtectedRoute> <AllWithdrawals /> </ProtectedRoute>} />
      <Route path="/admin/settings" element={<ProtectedRoute> <Settings /> </ProtectedRoute>} /> */}

      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
    </Routes>
  );
}

const ProtectedRoute = ({children}) => {
  const location = useLocation()
  const token = getAuthToken()

  if (token) {
    return children
  }

  return <Navigate to="/login" state={{ from: location }} replace />
}

export default App;
