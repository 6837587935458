import { toast } from "react-toastify"
import { addHash, depositService, getInvestService, getPaymentAddresses, getPlansService, getTransactionByReferenceService, getTransactionsService, investService, withdrawService } from "../services/wallet.service"
import { useAuthenticatedMutation, useAuthenticatedQuery } from "./query.hook"
import { reactQueryKeys } from "../config/query"
import { errorToaster, mutationError, successToaster } from "../helpers/error.helper"

export const useDeposit  = (body, schema) => useAuthenticatedMutation({
    mutationFn: async (e) => {
        e.preventDefault()

        const depositBody = {
            amount: body.amount * 100,
        }

        const data = await schema.validate(depositBody, {abortEarly: false, strict: true})
        const login = await depositService(data)
        return login
    },
    onSuccess: (data) => {
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = `/invoice/${data.data.transaction.reference}`
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})

export const useAddHash  = (body, schema) => useAuthenticatedMutation({
    mutationFn: async (e) => {
        e.preventDefault()

        const data = await schema.validate(body, {abortEarly: false, strict: true})
        const login = await addHash(data)
        return login
    },
    onSuccess: (data) => {
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = `/invoice/${data.data.transaction.reference}`
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})

export const useGetTransactions  = (query) => useAuthenticatedQuery({
    queryFn: async() => {return getTransactionsService(query)},
    queryKey: [reactQueryKeys.transactions, query]
})

export const useGetTransactionByReference  = (reference) => useAuthenticatedQuery({
    queryFn: async () => await getTransactionByReferenceService(reference),
    queryKey: [reactQueryKeys.transaction, reference]
})

export const useGetPlans  = () => useAuthenticatedQuery({
    queryFn: async () => await getPlansService(),
    queryKey: [reactQueryKeys.plans]
})

export const useInvest  = (body, schema) => useAuthenticatedMutation({
    mutationFn: async (e) => {
        e.preventDefault()
        
        const investBody = {
            amount: body.amount,
            planId: body.planId,
        }

        const data = await schema.validate(investBody, {abortEarly: false, strict: true})
        const deposit = await investService(data)
        return deposit
    },
    onSuccess: (data) => {
        const notify = () => successToaster(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = "/"
        }, 2000);
    },
    onError: (err) => {
        const errMessage = err?.response?.data?.message ? err?.response?.data?.message : err.message;
        const notify = () => errorToaster(errMessage)
        notify()
    }
})

export const useGetInvestments  = () => useAuthenticatedQuery({
    queryFn: async () => await getInvestService(),
    queryKey: [reactQueryKeys.investments]
})

export const useWithdrawRequest  = (body, schema) => useAuthenticatedMutation({
    mutationFn: async (e) => {
        e.preventDefault()

        const depositBody = {
            ...body,
            amount: body.amount * 100,
        }

        const data = await schema.validate(depositBody, {abortEarly: false, strict: true})
        const login = await withdrawService(data)
        return login
    },
    onSuccess: (data) => {
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = `/wallet`
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})

export const useGetPaymentAddresses  = () => useAuthenticatedQuery({
    queryFn: async () => await getPaymentAddresses(),
    queryKey: [reactQueryKeys.addresses]
})
