export const reactQueryKeys = {
    user: "user",
    wallet: "wallet",
    transactions: "transactions",
    transaction: "transaction",
    plans: "plans",
    investments: "investments",
    affiliates: "affiliates",
    addresses: "addresses",

    allUsers: "allUsers",
    adminUser: "adminUser",
    userWallet: "userWallet",
    userTransactions: "userTransactions",
    userInvestments: "userInvestments"
}