import { toast } from "react-toastify";
import { reactQueryKeys } from "../config/query";
import { mutationError } from "../helpers/error.helper";
import { allTransactionService, allUsersService, allWithdrawalsService, approveDeposit, approveWithDrawal, createAdminService, fundUser, getUserService, stopUserInvestment, userInvestmentsService, userTransactionService, userWalletService, userWithdrawalsService } from "../services/Admin.service";
import { useAuthenticatedMutation, useAuthenticatedQuery } from "./query.hook";

export const useGetAllUsers  = (name?: string) => useAuthenticatedQuery({
    queryFn:  () => allUsersService(`name=${name || ""}`),
    queryKey: [reactQueryKeys.allUsers]
})

export const useAdminGetUser  = (userId) => useAuthenticatedQuery({
    queryFn: async() => await getUserService(userId),
    queryKey: [reactQueryKeys.adminUser]
})

export const useAdminGetUserWallet  = (userId) => useAuthenticatedQuery({
    queryFn: async() => await userWalletService(userId),
    queryKey: [reactQueryKeys.userWallet, userId]
})

export const useAdminGetUserTransactions  = (userId, query) => useAuthenticatedQuery({
    queryFn: async() => await userTransactionService(userId, query),
    queryKey: [reactQueryKeys.userTransactions, userId, query]
})

export const useAdminGetAllTransactions  = (query) => useAuthenticatedQuery({
    queryFn: async() => await allTransactionService(query),
    queryKey: [reactQueryKeys.userTransactions, query]
})

export const useAdminGetAllWithdrawals  = (query) => useAuthenticatedQuery({
    queryFn: async() => await allWithdrawalsService(""),
    queryKey: ["allWithdrawals"]
})

export const useAdminGetUserInvestments  = (userId, query) => useAuthenticatedQuery({
    queryFn: async() => await userInvestmentsService(userId, query),
    queryKey: [reactQueryKeys.userInvestments, userId, query]
})

export const useAdminUserWithdrawalsService = (userId, query) => useAuthenticatedQuery({
    queryFn: async() => await userWithdrawalsService(userId, query),
    queryKey: ["user-withdrawals", userId, query]
})

export const useCreateAdmin = (body, schema) => useAuthenticatedMutation({
    // queryFn: createAdminService,
    mutationFn: async (e) => {
        e.preventDefault()

        const data = await schema.validate(body, {abortEarly: false, strict: true})
        const create = await createAdminService(data)
        return create
    },
    onSuccess: (data) => {
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = `/admin/all-users`
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})

export const useStopUserInvestment = (userId, schema) => useAuthenticatedMutation({
    // queryFn: createAdminService,
    mutationFn: async (investmentId) => {
        const data = await schema.validate({id: investmentId}, {abortEarly: false, strict: true})
        const create = await stopUserInvestment(userId, data)
        return create
    },
    onSuccess: (data) => {
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            // return window.location.href = `/admin/all-users/${userId}`
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})

export const useApproveDeposit = () => useAuthenticatedMutation({
    // queryFn: createAdminService,
    mutationFn: async (depositId) => {
        // e.preventDefault()

        // const data = await schema.validate(body, {abortEarly: false, strict: true})
        const create = await approveDeposit(depositId)
        return create
    },
    onSuccess: (data) => {
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = `/admin/all-users/${data.data.deposit.userID}`
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})


export const useApproveWithDrawal = (withdrawalId) => useAuthenticatedMutation({
    // queryFn: createAdminService,
    mutationFn: async (withdrawalId) => {
        // e.preventDefault()

        // const data = await schema.validate(body, {abortEarly: false, strict: true})
        const create = await approveWithDrawal(withdrawalId)
        return create
    },
    onSuccess: (data) => {
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = `/admin/all-users/${data.data.withdrawal.userID}`
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})

export const useFundUser = (body, schema) => useAuthenticatedMutation({
    // queryFn: createAdminService,
    mutationFn: async (e) => {
        e.preventDefault()

        const data = await schema.validate({...body, amount: body.amount*100}, {abortEarly: false, strict: true})
        const create = await fundUser(data)
        return create
    },
    onSuccess: (data) => {
        const notify = () => toast.success(data.message)
        notify()

        setTimeout(() => {
            return window.location.href = `/admin/all-users/${body.userId}`
        }, 2000);
    },
    onError: (err) => {
        const errMessage = mutationError(err)
        const notify = () => toast.error(errMessage)
        notify()
    }
})