import React from "react";
import Logo from "../assets/logo/lumina.png"

const AuthContainerComponent = ({children}) => {
  return (
    <div class="authincation">
        <div class="container">
            <div class="row justify-content-center align-items-center g-0">
                <div class="col-xl-8">
                    <div class="row g-0">
                        <div class="col-lg-6">
                            <div class="welcome-content">
                                <div class="welcome-title">
                                    <div class="mini-logo">
                                        <a href="index.html">
                                            <img src={Logo} alt="" width="30" /></a>
                                    </div>
                                    <h3>Welcome to {process.env.REACT_APP_COMPANY_NAME}</h3>
                                </div>
                                <div class="privacy-social">
                                    <div class="privacy-link"><a href="#">Have an issue with 2-factor
                                            authentication?</a><br /><a href="#">Privacy Policy</a></div>
                                    <div class="intro-social">
                                        <ul>
                                            <li><a href="https://t.me/luminatrustofficial" target="_blank"><i class="fi fi-brands-telegram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </div>
    // <div id="wrapper" class="theme-cyan">
    //   <div class="vertical-align-wrap">
    //     <div class="vertical-align-middle auth-main">
    //       <div class="auth-box">
    //         {children}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AuthContainerComponent;
