import React, { useEffect, useState } from 'react'
import * as Yup from "yup"
import { ToastContainer } from 'react-toastify'
import AuthContainerComponent from '../../components/AuthContainer.component'
import PrimaryButtonComponent from '../../components/PrimaryButton.component'

import Logo from "../../assets/logo/luminatrust.png"
import { Link, useParams } from 'react-router-dom'
import { useResetPassword } from '../../hooks/auth.hook'

const schema = Yup.object({
    password: Yup.string().min(8, "password must be at least 8 characters").required("password is required").label("password")
})
const ResetPassword = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [body, setBody] = useState({
        password: "",
        token: ""
    })

    const { token } = useParams();

    const resetPasswordMutation = useResetPassword(body, schema)

    useEffect(() => {
      setIsLoading(resetPasswordMutation.isPending)
      setBody(prevState => ({...prevState, token: token }))
    }, [resetPasswordMutation, token])
    
    return (
        <AuthContainerComponent>
            <div class="col-lg-6">
            <div class="auth-form">
                <h4>Reset Password</h4>
                <form onSubmit={(e) => resetPasswordMutation.mutate(e)}>
                    <div class="row">
                    <div class="col-12 mb-3">
                        <label class="form-label">Password</label>
                        <input name="password" onChange={(e) => setBody(prevState => ({...prevState, password: e.target.value}))} type="text" class="form-control" />
                    </div>
                    </div>
                    <div class="mt-3 d-grid gap-2">
                        <PrimaryButtonComponent title="RESET PASSWORD" isPending={isLoading} />
                    </div>
                </form>
                <p class="mt-3 mb-0 undefined">
                    Remembered your password ?
                    <Link class="text-primary" to="/login">
                    {" "}
                    Sign in
                    </Link>
                </p>
            </div>
        </div>
        {/* <div class="card">
            <div class="header">
            <div class="top">
                <img src={Logo} alt="Lumina trust" />
            </div>
            <p class="lead">Reset Your Password</p>
            </div>
            <div class="body">
            <form class="form-auth-small" onSubmit={(e) => resetPasswordMutation.mutate(e)}>
                <div class="form-group">
                <label for="signin-email" class="control-label sr-only">
                    New Password
                </label>
                <input
                    type="password"
                    class="form-control"
                    id="signin-email"
                    onChange={(e) => setBody(prevState => ({...prevState, password: e.target.value}))}
                    placeholder="Password"
                />
                </div>
                <PrimaryButtonComponent title="Reset Password" isPending={isLoading} />
                
                <div class="bottom">
                    <span class="helper-text m-b-10">
                        <Link to="/login">Remembered your password ? Login</Link>
                    </span>
                </div>
            </form>
            </div>
        </div> */}
        <ToastContainer position="top-center" />
        </AuthContainerComponent>
    )
}

export default ResetPassword