

export const getAuthToken = () => {
    return localStorage.getItem("authToken")
}

export const formatMoney = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(amount/100)
}

export const formatNumber = (amount) => {
    return new Intl.NumberFormat('en-US').format(amount)
}
