import React, { useEffect, useState } from 'react'
import * as Yup from "yup"
import { ToastContainer } from 'react-toastify'
import PrimaryButtonComponent from '../../components/PrimaryButton.component'
import AuthContainerComponent from "../../components/AuthContainer.component";
import { Link } from 'react-router-dom'

import Logo from "../../assets/logo/luminatrust.png"
import { useForgotPassword } from '../../hooks/auth.hook';

const schema = Yup.object({
    email: Yup.string().email("invalid email address").lowercase().required("email is required").label("email")
})
const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [body, setBody] = useState({
        email: "",
    })
    const forgotPasswordMutation = useForgotPassword(body, schema)

    useEffect(() => {
      setIsLoading(forgotPasswordMutation.isPending)
    
    }, [forgotPasswordMutation])
    
    return (
        <AuthContainerComponent>
            <div class="col-lg-6">
                <div class="auth-form">
                <h4>Forgot Password</h4>
                <form onSubmit={(e) => forgotPasswordMutation.mutate(e)}>
                    <div class="row">
                    <div class="col-12 mb-3">
                        <label class="form-label">Email</label>
                        <input name="email" onChange={(e) => setBody(prevState => ({...prevState, email: e.target.value}))} type="text" class="form-control" />
                    </div>
                    </div>
                    <div class="mt-3 d-grid gap-2">
                    <PrimaryButtonComponent title="FORGOT PASSWORD" isPending={isLoading} />
                    </div>
                </form>
                <p class="mt-3 mb-0 undefined">
                    Remembered your password ?
                    <Link class="text-primary" to="/login">
                    {" "}
                    Sign in
                    </Link>
                </p>
                </div>
            </div>
            <ToastContainer position="top-center" />
        </AuthContainerComponent>
        // <AuthContainerComponent>
        // <div class="card">
        //     <div class="header">
        //     <div class="top">
        //         <img src={Logo} alt="Lumina trust" />
        //     </div>
        //     <p class="lead">Forgot Password</p>
        //     </div>
        //     <div class="body">
        //     <form class="form-auth-small" onSubmit={(e) => forgotPasswordMutation.mutate(e)}>
        //         <div class="form-group">
        //         <label for="signin-email" class="control-label sr-only">
        //             Email
        //         </label>
        //         <input
        //             type="email"
        //             class="form-control"
        //             id="signin-email"
        //             onChange={(e) => setBody(prevState => ({...prevState, email: e.target.value}))}
        //             placeholder="Email"
        //         />
        //         </div>
        //         <PrimaryButtonComponent title="Send Link" isPending={isLoading} />
                
        //         <div class="bottom">
        //             <span class="helper-text m-b-10">
        //                 {/* <i class="fa fa-lock"></i>{" "} */}
        //                 <Link to="/login">Remembered your password ? Login</Link>
        //             </span>
        //         </div>
        //     </form>
        //     </div>
        // </div>
        // <ToastContainer position="top-center" />
        // </AuthContainerComponent>
    )
}

export default ForgotPassword