import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import DashContainerComponent from '../../components/DashContainer.component'
import { ToastContainer } from 'react-toastify'
import { useAdminGetAllTransactions, useApproveDeposit, useApproveWithDrawal, useAdminGetAllWithdrawals } from '../../hooks/admin.hook';
import { formatMoney } from '../../helpers/service.helper';
import dayjs from 'dayjs'
import Logo from "../../assets/logo/lumina.png";
import { badgesOptions } from '../../config/config';

const AllTransactions = () => {
    const [transactions, setTransactions] = useState([])
    const [withdrawals, setWithdrawals] = useState([])
    const [txCategory, setTxCategory] = useState("deposit")
    const { category } = useParams();

    const approveDepositMutate = useApproveDeposit();
    const approveWithdrawalMutate = useApproveWithDrawal();

    const transactionQuery = useAdminGetAllTransactions(`category=${txCategory}`)
    const withdrawalQuery = useAdminGetAllWithdrawals()

    useEffect(() => {
      setTransactions(transactionQuery?.data?.data?.transactions)
      setWithdrawals(withdrawalQuery?.data?.data?.withdrawals)
      setTxCategory(category)
    }, [category, transactions, transactionQuery, withdrawalQuery])
    
    return (
        <DashContainerComponent>
            <div className="content-body">
                <div class="container">

                    <div class="row">

                        <div className="col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <h2>{category}s</h2>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        {
                                            category === "deposit" && (
                                                <table class="table table-hover mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: "60px"}}>#</th>
                                                            <th>User</th>
                                                            <th>Reference</th>
                                                            {/* <th>Type</th> */}
                                                            <th>Hash</th>
                                                            <th>Crypto</th>
                                                            <th>Status</th>
                                                            <th>Amount</th>
                                                            <th>Date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        transactions && transactions.length > 0 && transactions.map(deposit => {
                                                                return (
                                                                    <tr>
                                                                        <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                                        <td>
                                                                            {deposit.user.firstName} {deposit.user.lastName}<br/>
                                                                            {deposit.user.email}
                                                                        </td>
                                                                        <td>{deposit.reference}</td>
                                                                        <td>{deposit.hash}</td>
                                                                        <td>{deposit.crypto}</td>
                                                                        <td><span class={badgesOptions[deposit.status.toLowerCase()]}>{deposit.status}</span></td>
                                                                        <td>{formatMoney(deposit.amount)}</td>
                                                                        <td>{dayjs(deposit.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                        <td>
                                                                            {
                                                                                deposit.status === "PENDING"
                                                                                ?
                                                                                <button className='btn btn-primary btn-lg btn-block' onClick={() => category === "deposit" ? approveDepositMutate.mutate(deposit.id) : approveWithdrawalMutate.mutate(deposit.id)}>Approve {category}</button>
                                                                                :
                                                                                <button className='btn btn-primary btn-lg btn-block' disabled>Approved</button>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                    
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                        {
                                            category === "withdrawal" && (
                                                <table class="table table-hover mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: "60px"}}>#</th>
                                                            <th>User</th>
                                                            <th>Reference</th>
                                                            <th>Crypto</th>
                                                            <th>Wallet</th>
                                                            <th>Amount</th>
                                                            <th>Date</th>
                                                            <th>Approve</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            withdrawals && withdrawals.length > 0 && withdrawals.map(withdrawal => {
                                                                    return (
                                                                        <tr>
                                                                            <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                                            <td>
                                                                                {withdrawal.user.firstName} {withdrawal.user.lastName}<br/>
                                                                                {withdrawal.user.email}
                                                                            </td>
                                                                            <td>{withdrawal.reference}</td>
                                                                            <td>{withdrawal.crypto}</td>
                                                                            <td>{withdrawal.wallet}</td>
                                                                            <td>{formatMoney(withdrawal.amount)}</td>
                                                                            <td>{dayjs(withdrawal.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                            <td>
                                                                                {
                                                                                    withdrawal.isPaid === false
                                                                                    ?
                                                                                    <button className='btn btn-primary btn-lg btn-block' onClick={() => approveWithdrawalMutate.mutate(withdrawal.id)}>Approve</button>
                                                                                    :
                                                                                    <button className='btn btn-primary btn-lg btn-block' disabled>Approved</button>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                        
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        <ToastContainer position="top-center" />
        </DashContainerComponent>
    )
}

export default AllTransactions