import axios from "axios"
import { getAuthToken } from "../helpers/service.helper"

const BASE_URL = process.env.REACT_APP_API_URL

export const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Accept': "application/json",
        'Content-Type': "application/json"
    }
})

export const authenticatedApi = () => axios.create({
    baseURL: BASE_URL,
    headers: {
        'Accept': "application/json",
        'Content-Type': "application/json",
        'Authorization': `Bearer ${getAuthToken()}`
    }
})