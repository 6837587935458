import { authenticatedApi } from "./Http.service";

export const allUsersService = async (query) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/admin/users?${query}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getUserService = async (userId) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/admin/user/${userId}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const createAdminService = async (body) => {
    try {
        const {data} = await authenticatedApi().post('/v1/admin/create', body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const userWalletService = async (userId) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/admin/wallet/${userId}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const userTransactionService = async (userId, query) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/admin/transactions/${userId}?${query}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const allTransactionService = async (query) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/admin/transactions?${query}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const userWithdrawalsService = async (userId, query) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/admin/withdrawals/${userId}?${query}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const userInvestmentsService = async (userId, query) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/admin/investments/${userId}?${query}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const stopUserInvestment = async (userId, body) => {
    try {
        const {data} = await authenticatedApi().post(`/v1/admin/investment/${userId}/stop`, body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const getUserReferrals = async (userId) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/admin/user/referrals/${userId}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const approveDeposit = async (depositId) => {
    try {
        const {data} = await authenticatedApi().post(`/v1/admin/deposits/${depositId}`, {})
        console.log("DD ", data);
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const approveWithDrawal = async (withdrawalId) => {
    try {
        const {data} = await authenticatedApi().post(`/v1/admin/withdrawals/${withdrawalId}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const fundUser = async (body) => {
    try {
        const {data} = await authenticatedApi().post(`/v1/admin/deposits/top-up`, body)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const allWithdrawalsService = async (query) => {
    try {
        const {data} = await authenticatedApi().get(`/v1/admin/transactions/withdrawals?${query}`)
        return data
    } catch (error) {
        console.log(error);
        throw error
    }
}
