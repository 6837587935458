import React, { useEffect, useState } from 'react'
import * as Yup from "yup"
import AuthContainerComponent from '../../components/AuthContainer.component'
import { Link, useLocation } from 'react-router-dom'
import { useRegister } from '../../hooks/auth.hook'
import { ToastContainer } from 'react-toastify';
import Logo from "../../assets/logo/luminatrust.png"
import PrimaryButtonComponent from '../../components/PrimaryButton.component'

const schema = Yup.object({
    firstname: Yup.string().required("first name is required").label("first name"),
    lastname: Yup.string().required("last name is required").label("last name"),
    username: Yup.string().required("username is required").label("username"),
    refererId: Yup.string().nullable().label("referer id"),
    email: Yup.string().email("invalid email address").lowercase().required("first name is required").label("email"),
    password: Yup.string().min(8, "password must be at least 8 characters").required("password is required").label("password")
})
const Register = () => {
    const location = useLocation();
    const [body, setBody] = useState({
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        password: "",
        refererId: ""
    })
    
    const registerUser = useRegister(body, schema)

    useEffect(() => {
      const search = location.search;
      const searchParam = new URLSearchParams(search)
      const referralCode = searchParam.get("referralCode");
    
      setBody(prevState => ({...prevState, refererId: referralCode}))
    }, [location])
    
    return (
        <AuthContainerComponent>
            <div class="col-lg-6">
                <div class="auth-form">
                    <h4>Sign Up</h4>
                    <form  onSubmit={(e) => registerUser.mutate(e)}>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <label class="form-label">First Name</label>
                                <input name="firstName" onChange={(e) => setBody(prevState => ({...prevState, firstname: e.target.value}))} type="text" class="form-control" />
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">Last Name</label>
                                <input name="firstName" onChange={(e) => setBody(prevState => ({...prevState, lastname: e.target.value}))} type="text" class="form-control" />
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">Username</label>
                                <input name="firstName" onChange={(e) => setBody(prevState => ({...prevState, username: e.target.value}))} type="text" class="form-control" />
                            </div>
                            <div class="col-12 mb-3"><label class="form-label">Email</label>
                                <input name="email" onChange={(e) => setBody(prevState => ({...prevState, email: e.target.value}))} type="text" class="form-control" />
                            </div>
                            <div class="col-12 mb-3"><label class="form-label">Password</label>
                                <input name="password" onChange={(e) => setBody(prevState => ({...prevState, password: e.target.value}))} type="password" class="form-control" />
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label">Referrer ID</label>
                                <input name="refererId" onChange={(e) => setBody(prevState => ({...prevState, refererId: e.target.value}))}  type="text" class="form-control" />
                                <span>Referer ID is not required</span>
                            </div>
                            <div class="col-12">
                                <div class="form-check">
                                    <input name="acceptTerms" type="checkbox" class="form-check-input" id="acceptTerms" required />
                                    <label class="form-check-label" for="acceptTerms">I certify that I am 18 years of age or older, and agree to the 
                                        <a href="#" class="text-primary">User Agreement</a> and <a href="#" class="text-primary">Privacy Policy</a>.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 d-grid gap-2">
                            {/* <button type="submit" class="btn btn-primary me-8 text-white">Sign Up</button> */}
                            <PrimaryButtonComponent title="REGISTER" isPending={registerUser.isPending} />
                        </div>
                    </form>
                    <p class="mt-3 mb-0 undefined">Already have an account?<Link class="text-primary" to="/login"> Sign In</Link></p>
                </div>
            </div>
            <ToastContainer position="top-center" />
        </AuthContainerComponent>
        // <AuthContainerComponent>
        //     <div class="card">
        //         <div class="header">
        //             <div class="top">
        //                 <img src={Logo} alt="Lumina trust" />
        //             </div>
        //             <p class="lead">Create an account</p>
        //         </div>
        //         <div class="body">
        //             <form class="form-auth-small" onSubmit={(e) => registerUser.mutate(e)}>
        //                 <div class="form-group">
        //                     <label for="signup-fname" class="control-label sr-only">First name</label>
        //                     <input onChange={(e) => setBody(prevState => ({...prevState, firstname: e.target.value}))} type="text" class="form-control" id="signup-fname" placeholder="Your first name" />
        //                 </div>
        //                 <div class="form-group">
        //                     <label for="signup-lname" class="control-label sr-only">Last name</label>
        //                     <input onChange={(e) => setBody(prevState => ({...prevState, lastname: e.target.value}))} type="text" class="form-control" id="signup-lname" placeholder="Your last name" />
        //                 </div>
        //                 <div class="form-group">
        //                     <label for="signup-email" class="control-label sr-only">Email</label>
        //                     <input onChange={(e) => setBody(prevState => ({...prevState, email: e.target.value}))} type="email" class="form-control" id="signup-email" placeholder="Your email" />
        //                 </div>
        //                 <div class="form-group">
        //                     <label for="signup-username" class="control-label sr-only">Username</label>
        //                     <input onChange={(e) => setBody(prevState => ({...prevState, username: e.target.value}))} type="text" class="form-control" id="signup-username" placeholder="Your username" />
        //                 </div>
        //                 <div class="form-group">
        //                     <label for="signup-password" class="control-label sr-only">Password</label>
        //                     <input onChange={(e) => setBody(prevState => ({...prevState, password: e.target.value}))} type="password" class="form-control" id="signup-password" placeholder="Password" />
        //                 </div>
        //                 <div class="form-group">
        //                     <label for="signup-refer" class="control-label sr-only">Referer ID</label>
        //                     <input disabled={true} onChange={(e) => setBody(prevState => ({...prevState, refererId: e.target.value}))} value={body.refererId} type="text" class="form-control" id="signup-refer" placeholder="Your Referer id" />
        //                     <span>Referer ID is not required</span>
        //                 </div>
        //                 {/* <button type="submit" class="btn btn-primary btn-lg btn-block">REGISTER</button> */}
        //                 <PrimaryButtonComponent title="REGISTER" isPending={registerUser.isPending} />
        //                 <div class="bottom">
        //                     <span class="helper-text">Already have an account? <Link to="/login">Login</Link></span>
        //                 </div>
        //             </form>
        //         </div>
        //     </div>
        //     <ToastContainer position="top-center" />
        // </AuthContainerComponent>
    )
}

export default Register
