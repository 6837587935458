import { toast } from "react-toastify"
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { ToastContainer } from "react-toastify";
import DashContainerComponent from '../../components/DashContainer.component'
// import SpaceShip from "../../assets/images/space-ship.png"
import { useGetPlans, useInvest } from '../../hooks/wallet.hook'
import { formatMoney } from '../../helpers/service.helper'
import PlanModalComponent from '../../components/PlanModal.component'
import PrimaryButtonComponent from '../../components/PrimaryButton.component'

import Logo from '../../assets/logo/lumina.png'


const schema = (minimum) => Yup.object({
    amount: Yup.number().positive().min(minimum).required("amount is required").label("amount")
})
const Plans = () => {
    const [displayModal, setDisplayModal] = useState(false)
    const [currentPlan, setCurrentPlan] = useState(null)
    const [inputValues, setInputValues] = useState(Array.from({length: 4}, () => ''))

    const [plans, setPlans] = useState([]);
    const [body, setBody] = useState({
      amount: 0,
      planId: 0
    })

    const [calculateBody, setCalculateBody] = useState({
      planId: 0,
      amount: 0,
      period: 0
  })
  const [totalRoi, setTotalRoi] = useState(0)

    const handleInputChange = (index, value) => {
        setInputValues((prevValues) => {
            const newValues = [...prevValues];
            newValues[index] = value
            return newValues
        })
    }

    
    const investMutation = useInvest(body, schema(currentPlan?.minimumAmount || 10000))

    const handleOnModal = (e, planId) => {
        e.preventDefault();
        const id = parseInt(planId)
        const planAmount = parseInt(inputValues[id] || "0")
        setBody(prevState => ({...prevState, amount: planAmount*100, planId: id}))
        const plan = plans.find(x => x.id === id);
        setCurrentPlan(plans.find(x => x.id === id))
        console.log(currentPlan, plan);

        if (planAmount*100 < plan.minimumAmount) {
          toast.error(`The minimum amount for this plan is ${formatMoney(plan.minimumAmount)}`);
          return
        }

        if (planAmount*100 > plan.maximumAmount) {
          toast.error(`The maximum amount for this plan is ${formatMoney(plan.maximumAmount)}`);
          return
        }

        // setDisplayModal(true)
        investMutation.mutate(e)
    }

    const handleCalculateProfit = (e) => {
      e.preventDefault();

      const amount = calculateBody.amount * 100;

      const plan = plans.find(x => x.id === calculateBody.planId);
      if (!plan) {
        toast.error(`Invalid plan`);
        return
      }

      if (amount < plan.minimumAmount) {
        toast.error(`The minimum amount for this plan is ${formatMoney(plan.minimumAmount)}`);
        return
      }

      if (amount > plan.maximumAmount) {
        toast.error(`The maximum amount for this plan is ${formatMoney(currentPlan.maximumAmount)}`);
        return
      }

      const roi = plan.dailyReturn;


      const dailyRoi = (roi/100) * amount;

      const totalRoi = dailyRoi * calculateBody.period;
      setTotalRoi(totalRoi)

    }
    
    const closeModal = () => {
        setDisplayModal(false)
    }

    const planQuery = useGetPlans()

    useEffect(() => {
      setPlans(planQuery.data?.data?.plans)
    
    }, [planQuery])
    

    return (
      <DashContainerComponent>
        <div class="content-body">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-xl-4">
                                    <div class="page-title-content">
                                        <h3>Plans</h3>
                                        <p class="mb-2">Welcome to {process.env.REACT_APP_COMPANY_NAME}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="profile-wallet-nav">
                                    <ul class="nav nav-tabs">
                                      {
                                        plans && plans.length > 0 && plans.map(plan => (
                                          <li>
                                            <a data-bs-toggle="tab" href={"#"+plan.name.replace(" ","-").toLowerCase()}>
                                                <span class="icons usd">
                                                    <i class="fi fi-rr-bank"></i>
                                                </span>
                                                  {plan.name}
                                                <span><i class="fi fi-bs-angle-right"></i></span>
                                            </a>
                                        </li>
                                        ))
                                      }
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8">
                        <div class="tab-content profile-wallet-tab">
                          {
                            plans && plans.length > 0 && plans.map((plan, index) => (
                              <div class="tab-pane fade" id={plan.name.replace(" ","-").toLowerCase()}>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="wallet-progress-data">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <span>Min. Amount</span>
                                                    <h3>{formatMoney(plan.minimumAmount)}</h3>
                                                </div>
                                                <div class="text-end">
                                                    <span>Max. Amount</span>
                                                    <h3>{formatMoney(plan.maximumAmount)}</h3>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" style={{width: "100%"}} role="progressbar">
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between mt-2">
                                                <span>Daily Return: {plan.dailyReturn}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                      <h4 class="card-title">
                                          {plan.name}
                                      </h4>
                                    </div>
                                    <div class="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <dic className="card">
                                            <div className="card-body">
                                              <div class="buy-sell-widget">
                                                <form onSubmit={(e) => handleOnModal(e, plan.id)} class="currency_validate">
                                                  <div class="mb-3">
                                                    <label class="form-label mb-3">Amount</label>
                                                    <div class="input-group mb-3">
                                                      <input type="number" onChange={(e) => handleInputChange(plan.id, e.target.value)} className="form-select" />
                                                    </div>
                                                  </div>
                                                  <PrimaryButtonComponent title="INVEST" isPending={investMutation.isPending} />
                                                </form>
                                              </div>
                                            </div>
                                          </dic>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            ))
                          }
                            
                        </div>
                        <div className="card">
                          <div className="card-body">
                            <div className="col-md-6">
                              <div className="card">
                                <div className="card-body">
                                  <div class="buy-sell-widget">
                                    <form onSubmit={(e) => handleCalculateProfit(e)} name="myform" class="currency_validate">
                                      <h3>Profit Calculator</h3>
                                      <div class="mb-3">
                                          <label class="form-label mb-3">Select Plan</label>
                                          <div class="input-group mb-3">
                                              {/* <div class="input-group-prepend">
                                                  <label class="input-group-text">
                                                      <i class="fi fi-rr-usd-circle"></i>
                                                  </label>
                                              </div> */}
                                              <select onChange={(e) => setCalculateBody(prevState => ({...prevState, planId: parseInt(e.target.value)}))} className="form-control">
                                                <option value="">select plan</option>
                                                {
                                                  plans && plans.length > 0 && plans.map(plan => (
                                                    <option value={plan.id}>{plan.name}</option>
                                                  ))
                                                }
                                              </select>
                                          </div>
                                      </div>
                                      <div class="mb-3">
                                          <label class="form-label mb-3">Amount</label>
                                          <div class="input-group mb-3">
                                              <div class="input-group-prepend">
                                                  <label class="input-group-text">
                                                      <i class="fi fi-rr-usd-circle"></i>
                                                  </label>
                                              </div>
                                              <input type="number" onChange={(e) => setCalculateBody(prevState => ({...prevState, amount: parseInt(e.target.value)}))} className="form-control" />
                                          </div>
                                      </div>
                                      <div class="mb-3">
                                          <label class="form-label mb-3">Number of days.</label>
                                          <div class="input-group mb-3">
                                              <div class="input-group-prepend">
                                                  <label class="input-group-text"><i class="fi fi-rr-credit-card"></i>
                                                  </label>
                                              </div>
                                              <input type="number" onChange={(e) => setCalculateBody(prevState => ({...prevState, period: parseInt(e.target.value)}))} className="form-control" />
                                          </div>
                                      </div>
                                      <div class="mb-3">
                                        <div class="d-flex justify-content-between mt-3">
                                          <p class="mb-0">Total ROI</p>
                                          <h6 class="mb-0">{formatMoney(totalRoi)}</h6>
                                        </div>
                                      </div>
                                      <button class="btn btn-success btn-block">Calculate Profit</button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <ToastContainer position="top-center" />
      </DashContainerComponent>
      // <DashContainerComponent>
      //   <div id="main-content" style={{marginTop: "100px"}}>
      //     <div class="container-fluid">
      //       <div class="row clearfix">
      //         {plans && plans.length > 0 &&
      //           plans.map((plan) => (
      //             <div class="col-lg-4 col-md-12" key={plan.id}>
      //               <div class="card pricing2">
      //                 <div class="body">
      //                   <div class="pricing-plan">
      //                     <img src={Logo} width="50" height="50" alt="" class="pricing-img" />
      //                     <h2 class="pricing-header">{plan.name}</h2>
      //                     <ul class="pricing-features">
      //                       <li>Daily Return: {plan.dailyReturn}%</li>
      //                       <li>Capital Return: Yes</li>
      //                       <li>Return Type: Lifetime</li>
      //                       <li>Total Periods: Unlimited Days</li>
      //                       <li>Cancellation: Anytime</li>
      //                       <li>Total Revenue: Infinity</li>
      //                       <li>Saturdays & Sundays are Holidays</li>
      //                     </ul>
      //                     <span class="pricing-price">
      //                       {formatMoney(plan.minimumAmount)}
      //                     </span>
      //                     <small>to</small>
      //                     <span class="pricing-price">
      //                       {formatMoney(plan.maximumAmount)}
      //                     </span>
      //                     <input
      //                       className="form-control"
      //                       id={`myInput${plan.id}`}
      //                       type="number"
      //                       value={inputValues[plan.id]}
      //                       onChange={(e) => handleInputChange(plan.id, e.target.value)}
      //                       min={plan.minimumAmount/100}
      //                       max={plan.maximumAmount/100}
      //                     />
      //                     <br />
      //                     <a onClick={() => handleOnModal(plan.id)} class="btn btn-outline-primary">
      //                       Invest Now
      //                     </a>
      //                     {/* <a
                            
      //                       class="btn btn-outline-primary"
      //                     >
      //                       Invest Now
      //                     </a> */}
      //                   </div>
      //                 </div>
      //               </div>
      //             </div>
      //           ))}
      //       </div>
      //     </div>
      //   </div>
      //   <PlanModalComponent plan={currentPlan} body={body} show={displayModal} closeModal={closeModal} />
      // </DashContainerComponent>
    );
}

export default Plans