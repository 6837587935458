import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import * as Yup from "yup"
import { Link } from 'react-router-dom'
import DashContainerComponent from '../../components/DashContainer.component'

// import UserPhoto from '../../assets/images/user.png'
import { useChangePassword, useGetUser, useGetUserAffiliate } from '../../hooks/user.hook'


import Avatar from "../../assets/images/profile/3.png";
import AndroidImg from "../../assets/images/android.svg";
import AppleImg from "../../assets/images/apple.svg";

import { ToastContainer } from 'react-toastify'
import PrimaryButtonComponent from '../../components/PrimaryButton.component'
import { getUserReferralsByLevel } from '../../services/User.service'

const passwordSchema = Yup.object({
    password: Yup.string().min(8, "password must be at least 8 characters").required("password is required").label("password")
})

const Profile = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [body, setBody] = useState({
        password: ""
    })

    const [referrals, setReferrals] = useState([])
    const [user, setUser] = useState(null)

    const [levelTwo, setLevelTwo] = useState([])
    const [levelTwoUser, setLevelTwoUser] = useState("");

    const [levelThree, setLevelThree] = useState([])
    const [levelThreeUser, setLevelThreeUser] = useState("");

    const [levelFour, setLevelFour] = useState([])
    const [levelFourUser, setLevelFourUser] = useState("");

    const [levelFive, setLevelFive] = useState([])
    const [levelFiveUser, setLevelFiveUser] = useState("");

    const referralsQuery = useGetUserAffiliate();
    const userQuery = useGetUser()

    const changePasswordMutation = useChangePassword(body, passwordSchema)

    const  getThisUserReferralsByLevel = async(userId, level, name) => {
        try {
            if (level > 4) {
                alert("You can't go more than 5 level deep")
            }
            
            const referrals = await getUserReferralsByLevel(userId)
            if (level === 2) {
                setLevelThree([])
                setLevelTwo(referrals?.data?.referrals)
                setLevelTwoUser(name)
            }

            if (level === 3) {
                setLevelFour([])
                setLevelThree(referrals?.data?.referrals)
                setLevelThreeUser(name)
            }

            if (level === 4) {
                setLevelFive([])
                setLevelFour(referrals?.data?.referrals)
                setLevelFourUser(name)
            }

            if (level === 5) {
                setLevelFive(referrals?.data?.referrals)
                setLevelFiveUser(name)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
      if (referralsQuery.data?.data?.user) {
        setReferrals(referralsQuery.data?.data?.user)
      }

      if (userQuery.data?.data?.user) {
        setUser(userQuery.data?.data?.user)
      }

      setIsLoading(changePasswordMutation.isPending)

    }, [referralsQuery, userQuery, changePasswordMutation])
    
  return (
    <DashContainerComponent>
        <div class="content-body">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-xl-4">
                                    <div class="page-title-content">
                                        <h3>Settings</h3>
                                        <p class="mb-2">Your profile</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xxl-12 col-xl-12">
                        <div class="row">
                            <div class="col-xxl-6 col-xl-6 col-lg-6">
                                <div class="card ">
                                    <div class="card-body">
                                        <div class="welcome-profile">
                                            <div class="d-flex align-items-center">
                                                <img src={Avatar} alt="" />
                                                <div class="ms-3">
                                                    <h4>Welcome, {user?.firstName} {user?.lastName} !</h4>
                                                    <p>This is your profile overview.</p>
                                                </div>
                                            </div>
                                            <ul>
                                                <li><a href="#"><span class="verified"><i class="fi fi-bs-check"></i></span>Verify
                                                        account</a></li>
                                                <li><a href="#"><span class="not-verified"><i class="fi fi-rs-shield-check"></i></span>Two-factor
                                                        authentication
                                                        (Coming Soon !!!)</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-6 col-xl-6 col-lg-6">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Download App (Coming soon)</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="app-link">
                                            <h5>Get Verified On Our Mobile App</h5>
                                            <p>Verifying your identity on our mobile app more secure, faster, and reliable.
                                            </p>
                                            <a href="#" class="btn btn-primary"><img src={AndroidImg} alt="" /></a><br/>
                                            <div class="mt-3"></div>
                                            <a href="#" class="btn btn-primary"><img src={AppleImg} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-12 col-xl-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">Earn 1-5% Commission </h4>
                                    </div>
                                    <div class="card-body">
                                        <p>Refer your friends and earn up to 5% of <br/> their deposit.</p>
                                        <Link to="/affiliates" class="btn btn-primary">Referral Program</Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-8">
                                <div class="card">
                                    <div class="card-header flex-row">
                                        <h4 class="card-title">Information </h4>
                                        <a class="btn btn-primary" href="settings-profile.html">Edit</a>
                                    </div>
                                    <div class="card-body">
                                        <form class="row">
                                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div class="user-info">
                                                    <span>Name</span>
                                                    <h4>{user?.firstName} {user?.lastName}</h4>
                                                </div>
                                            </div>
                                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div class="user-info">
                                                    <span>EMAIL ADDRESS</span>
                                                    <h4>{user?.email}</h4>
                                                </div>
                                            </div>
                                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div class="user-info">
                                                    <span>Username</span>
                                                    <h4>{user?.username}</h4>
                                                </div>
                                            </div>
                                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div class="user-info">
                                                    <span>JOINED SINCE</span>
                                                    <h4>{dayjs(user?.createdAt).format("MMM DD, YYYY HH:mm a")}</h4>
                                                </div>
                                            </div>
                                            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div class="user-info">
                                                    <span>TYPE</span>
                                                    <h4>Personal</h4>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-4">
                                <div class="card">
                                    <div class="card-header flex-row">
                                        <h4 class="card-title">Change Password </h4>
                                    </div>
                                    <div class="card-body">
                                        <form onSubmit={(e) => changePasswordMutation.mutate(e)}>
                                            <div class="row mb-3">
                                                <div class="col-lg-12 col-md-12">
                                                    <h6>Change Password</h6>
                                                    <div class="form-group">
                                                        <input type="password" onChange={(e) => setBody(prevState => ({...prevState, password: e.target.value}))} class="form-control" placeholder="New Password" />
                                                    </div>
                                                    {/* <div class="form-group">
                                                        <input type="password" class="form-control" placeholder="Confirm New Password" />
                                                    </div> */}
                                                </div>
                                            </div>
                                            <PrimaryButtonComponent title="Update" isPending={isLoading} /> &nbsp;&nbsp;
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </DashContainerComponent>
    // <DashContainerComponent>
    //     <div id="main-content" style={{marginTop: "100px"}}>
    //         <div className="container-fluid">
    //             <div class="row clearfix">

    //             <div class="col-lg-3 col-md-12">
    //                 <div class="card profile-header">
    //                     <div class="body">
    //                         <div class="profile-image"> <img src={UserPhoto} class="rounded-circle" alt=""/> </div>
    //                         {
    //                             user ?
    //                             <div>
    //                                 <h4 class="mb-0"><strong>{user.firstName}</strong> {user.lastName}</h4>
    //                                 <span>@{user.username}</span><br/>
    //                                 <span>{user.email}</span>
    //                             </div>
    //                             :
    //                             <div>
    //                                 <h4 class="mb-0"><strong>--</strong> --</h4>
    //                                 <span>--</span>
    //                             </div> 
    //                         }                          
    //                     </div>
    //                 </div>

                    
    //                 <div class="card">
    //                     <div class="header">
    //                         <h2>Your Referrals</h2>
    //                     </div>
    //                     <div class="body">
    //                         <ul class="right_chat list-unstyled li_animation_delay">
    //                             {
    //                                 referrals.length > 0 && referrals.map(ref => (
    //                                     <li class="online">
    //                                         <a href="javascript:void(0);">
    //                                             <div class="media">
    //                                                 <img class="media-object " src={Avatar} alt="" />
    //                                                 <div class="media-body">
    //                                                     <span class="name">{ref.firstName} {ref.lastName}</span>
    //                                                     <span class="message">@{ref.username}</span>
    //                                                     <span class="badge badge-outline status"></span>
    //                                                 </div>
    //                                             </div>
    //                                         </a>
    //                                     </li>
    //                                 ))
    //                             }
                                                        
    //                         </ul>
    //                     </div>
    //                 </div>
                    
    //             </div>

    //             <div class="col-lg-6 col-md-12">

    //                 <div class="card">
    //                     <div class="body">
    //                         <ul class="nav nav-tabs-new">
    //                             <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#Overview">Overview</a></li>
    //                             <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Settings">Settings</a></li>
    //                             <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Referrals">Referrals</a></li>
    //                         </ul>
    //                     </div>
    //                 </div>

    //                 <div class="tab-content padding-0">

    //                     <div class="tab-pane active" id="Overview">
    //                         <div class="card">
    //                             <div class="header">
    //                                 <h2>Info</h2>
    //                             </div>
    //                             <div class="body">
    //                                 <small class="text-muted">Full name: </small>
    //                                 <p>{user ? user.firstName : "--"} {user ? user.lastName : "--"}</p>
                                   
    //                                 <hr />
    //                                 <small class="text-muted">Email address: </small>
    //                                 <p>{user ? user.email : "--"}</p>                            
    //                                 <hr />
    //                                 <small class="text-muted">Username: </small>
    //                                 <p>@{user ? user.username : "--"}</p>
    //                                 {/* <hr />
    //                                 <small class="text-muted">Birth Date: </small>
    //                                 <p class="mb-0">October 22th, 1990</p> */}
    //                                 <hr />
    //                                 <small class="text-muted">Affiliate URL: </small>
    //                                 {
    //                                     user &&
    //                                     <p><i class="fa fa-globe m-r-5"></i>
    //                                         <a target="_blank" href={window.location.origin+"/register?referralCode="+user.affiliateId}>Link</a>
    //                                     </p>
    //                                 }
    //                                 {/* <p><i class="fa fa-facebook  m-r-5"></i> facebook.com/example</p>
    //                                 <p><i class="fa fa-github m-r-5"></i> github.com/example</p>
    //                                 <p><i class="fa fa-instagram m-r-5"></i> instagram.com/example</p> */}
    //                             </div>
    //                         </div>
    //                     </div>

    //                     <div class="tab-pane" id="Settings">
    //                         <div class="card">
    //                             <div class="body">
    //                                 <form onSubmit={(e) => changePasswordMutation.mutate(e)}>
    //                                     <div class="row clearfix">
    //                                         <div class="col-lg-12 col-md-12">
    //                                             <h6>Change Password</h6>
    //                                             <div class="form-group">
    //                                                 <input type="password" onChange={(e) => setBody(prevState => ({...prevState, password: e.target.value}))} class="form-control" placeholder="New Password" />
    //                                             </div>
    //                                             {/* <div class="form-group">
    //                                                 <input type="password" class="form-control" placeholder="Confirm New Password" />
    //                                             </div> */}
    //                                         </div>
    //                                     </div>
    //                                     <PrimaryButtonComponent title="Update" isPending={isLoading} /> &nbsp;&nbsp;
    //                                 </form>
    //                             </div>
    //                         </div>

    //                         <div class="card">
    //                             <div class="body">
    //                                 <h6><span className='fa fa-shield'></span> 2FA </h6>
    //                                 <div class="row">
                                        
    //                                 </div>
    //                                 <button type="button" class="btn btn-primary" disabled>Coming Soon</button> &nbsp;&nbsp;
                                    
    //                             </div>
    //                         </div>
    //                     </div>

    //                     <div class="tab-pane" id="Referrals">
    //                         <div class="card">
    //                             <div class="body">
    //                             <div className="row">
    //                                 <div className="col-lg-4">
    //                                     <div className="card">
    //                                         <div className="header">
    //                                             LEVEL 1
    //                                         </div>
    //                                         <div className="body">
    //                                             <ul>
    //                                                 {
    //                                                     referrals && referrals.length > 0 && referrals.map(user => {
    //                                                         return (
    //                                                             // 2 here means it should show mw level 2
    //                                                             <li style={{cursor: 'pointer'}} onClick={() => getThisUserReferralsByLevel(user.id, 2, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
    //                                                         )
    //                                                     })
    //                                                 }
                                                    
    //                                             </ul>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 {
    //                                     levelTwo && levelTwo.length > 0 && (
    //                                         <div className="col-lg-4">
    //                                             <div className="card">
    //                                                 <div className="header">
    //                                                     LEVEL 2 ({levelTwoUser})
    //                                                 </div>
    //                                                 <div className="body">
    //                                                     <ul>
    //                                                         {
    //                                                             levelTwo && levelTwo.length > 0 && levelTwo.map(user => {
    //                                                                 return (
    //                                                                     // 2 here means it should show mw level 2
    //                                                                     <li style={{cursor: 'pointer'}} onClick={() => getThisUserReferralsByLevel(user.id, 3, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
    //                                                                 )
    //                                                             })
    //                                                         }
    //                                                     </ul>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     )
    //                                 }
    //                                 {
    //                                     levelThree && levelThree.length > 0 && (
    //                                         <div className="col-lg-4">
    //                                             <div className="card">
    //                                                 <div className="header">
    //                                                     LEVEL 3 ({levelThreeUser})
    //                                                 </div>
    //                                                 <div className="body">
    //                                                     <ul>
    //                                                         {
    //                                                             levelThree && levelThree.length > 0 && levelThree.map(user => {
    //                                                                 return (
    //                                                                     // 2 here means it should show mw level 2
    //                                                                     <li style={{cursor: 'pointer'}} onClick={() => getThisUserReferralsByLevel(user.id, 5, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
    //                                                                 )
    //                                                             })
    //                                                         }
    //                                                     </ul>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     )
    //                                 }
    //                                 {
    //                                     levelFour && levelFour.length > 0 && (
    //                                         <div className="col-lg-4">
    //                                             <div className="card">
    //                                                 <div className="header">
    //                                                     LEVEL 4 ({levelFourUser})
    //                                                 </div>
    //                                                 <div className="body">
    //                                                     <ul>
    //                                                         {
    //                                                             levelFour && levelFour.length > 0 && levelFour.map(user => {
    //                                                                 return (
    //                                                                     // 2 here means it should show mw level 2
    //                                                                     <li style={{cursor: 'pointer'}} onClick={() => getThisUserReferralsByLevel(user.id, 3, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
    //                                                                 )
    //                                                             })
    //                                                         }
    //                                                     </ul>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     )
    //                                 }
    //                                 {
    //                                     levelFive && levelFive.length > 0 && (
    //                                         <div className="col-lg-4">
    //                                             <div className="card">
    //                                                 <div className="header">
    //                                                     LEVEL 5 ({levelFiveUser})
    //                                                 </div>
    //                                                 <div className="body">
    //                                                     <ul>
    //                                                         {
    //                                                             levelFive && levelFive.length > 0 && levelFive.map(user => {
    //                                                                 return (
    //                                                                     // 2 here means it should show mw level 2
    //                                                                     <li style={{cursor: 'pointer'}}>{user.firstName} {user.lastName}</li>
    //                                                                 )
    //                                                             })
    //                                                         }
    //                                                     </ul>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     )
    //                                 }
    //                                 </div>
    //                             </div>
    //                         </div>

    //                         <div class="card">
    //                             <div class="body">
    //                                 <h6><span className='fa fa-shield'></span> 2FA </h6>
    //                                 <div class="row">
                                        
    //                                 </div>
    //                                 <button type="button" class="btn btn-primary" disabled>Coming Soon</button> &nbsp;&nbsp;
                                    
    //                             </div>
    //                         </div>
    //                     </div>

    //                 </div>
                    
    //             </div>

    //             </div>
    //         </div>
    //     </div>
    // <ToastContainer position="top-center" />
    // </DashContainerComponent>
  )
}

export default Profile