import React, { useState, useEffect } from 'react'
import DashContainerComponent from '../../components/DashContainer.component'
import { ToastContainer } from "react-toastify";
import { useGetUser, useGetUserAffiliate } from '../../hooks/user.hook'


// import Avatar from "../../assets/images/xs/avatar4.jpg"
import PrimaryButtonComponent from '../../components/PrimaryButton.component'
import { getUserReferralsByLevel } from '../../services/User.service'
import { infoToaster } from '../../helpers/error.helper';


const Affiliate = () => {
  const [referrals, setReferrals] = useState([])
  const [user, setUser] = useState(null)
  const [currentLevel, setCurrentLevel] = useState(0);


  const [levelTwo, setLevelTwo] = useState([])
  const [levelTwoUser, setLevelTwoUser] = useState("");

  const [levelThree, setLevelThree] = useState([])
  const [levelThreeUser, setLevelThreeUser] = useState("");

  const [levelFour, setLevelFour] = useState([])
  const [levelFourUser, setLevelFourUser] = useState("");

  const [levelFive, setLevelFive] = useState([])
  const [levelFiveUser, setLevelFiveUser] = useState("");

  const referralsQuery = useGetUserAffiliate();
  const userQuery = useGetUser()

  const copyRefCode = () => {
    var copyText = document.getElementById("refCode");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    // Alert the copied text
    infoToaster("You have copied referral url to your clipboard")
    // alert("Copied the text: " + copyText.value);
  }

  const  getThisUserReferralsByLevel = async(userId, level, name) => {
      try {
          if (level > 4) {
              alert("You can't go more than 5 level deep")
          }
          
          const referrals = await getUserReferralsByLevel(userId)
          if (level === 2) {
              setLevelThree([])
              setLevelTwo(referrals?.data?.referrals)
              setLevelTwoUser(name)
          }

          if (level === 3) {
              setLevelFour([])
              setLevelThree(referrals?.data?.referrals)
              setLevelThreeUser(name)
          }

          if (level === 4) {
              setLevelFive([])
              setLevelFour(referrals?.data?.referrals)
              setLevelFourUser(name)
          }

          if (level === 5) {
              setLevelFive(referrals?.data?.referrals)
              setLevelFiveUser(name)
          }
      } catch (error) {
          console.log(error);
      }
  }

  useEffect(() => {
    if (referralsQuery.data?.data?.user) {
      setReferrals(referralsQuery.data?.data?.user)
    }

    if (userQuery.data?.data?.user) {
      setUser(userQuery.data?.data?.user)
    }
    
    if (user) {
      const phase1 = 30;
      const phase2 = 60;
      const phase3 = 100;

      if (user.totalReferrals <= phase1) {
        setCurrentLevel(1)
      } else if(user.totalReferrals <= phase2 && user.totalReferrals > phase1) {
        setCurrentLevel(3)
      } else {
        setCurrentLevel(5)
      }
    }

  }, [referralsQuery, userQuery])
    
  return (
    <DashContainerComponent>
      <div class="content-body">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="page-title">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-xl-4">
                                    <div class="page-title-content">
                                        <h3>Affiliates</h3>
                                        <p class="mb-2">View all your affiliates</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-3 col-lg-3">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Your Credit</h4>
                            </div>
                            <div class="card-body">
                                <div class="credit-content">
                                    <div class="invited d-flex justify-content-between">
                                        <h6>Referrals</h6>
                                        <h6 class="text-primary">{user?.totalReferrals}</h6>
                                    </div>
                                    {/* <div class="earnings d-flex justify-content-between">
                                        <h6>Earnings</h6>
                                        <h6 class="text-primary">$ 111</h6>
                                    </div>
                                    <button class="btn btn-primary">CLAIM REWARDS</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-lg-9">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Referral link</h4>
                            </div>
                            <div class="card-body">
                                <p>Earn from 1-5% in commissions referring investors when they deposit.</p>
                                <div class="referral-form">
                                    <form action="#">
                                        <div class="form-row align-items-center">
                                            <div class="mb-3 col-xl-12">
                                                <label>Your Referral Link</label>
                                                <input type="text" value={window.location.origin+"/register?referralCode="+user?.affiliateId} class="form-control" id="refCode" placeholder="Your Referral link" />
                                                <div class="edit-copy" onClick={copyRefCode}>
                                                    <span><i class="fi fi-rr-copy-alt"></i></span>
                                                </div>
                                            </div>
                                            {/* <div class="form-social col-xl-4">
                                                <a href="#">
                                                    <span><i class="fi fi-brands-facebook"></i></span>
                                                </a>
                                                <a href="#">
                                                    <span><i class="fi fi-brands-twitter"></i></span>
                                                </a>
                                                <a href="#">
                                                    <span><i class="fi fi-brands-instagram"></i></span>
                                                </a>
                                                <a href="#">
                                                    <span><i class="fi fi-brands-whatsapp"></i></span>
                                                </a>
                                            </div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        

                        <div class="refferal-level" >
                            <div class="card">
                                <div class="card-body">
                                  <div className="row">
                                    <div className="col-lg-4">
                                        <div className="card">
                                            <div className="card-header">
                                                LEVEL 1
                                            </div>
                                            <div className="card-body">
                                                <ul>
                                                    {
                                                        referrals && referrals.length > 0 && referrals.map(user => {
                                                            return (
                                                                // 2 here means it should show mw level 2
                                                                <li style={{cursor: 'pointer'}} onClick={() => getThisUserReferralsByLevel(user.id, 2, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
                                                            )
                                                        })
                                                    }
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        levelTwo && levelTwo.length > 0 && (
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        LEVEL 2 ({levelTwoUser})
                                                    </div>
                                                    <div className="card-body">
                                                        <ul>
                                                            {
                                                                levelTwo && levelTwo.length > 0 && levelTwo.map(user => {
                                                                    return (
                                                                        // 2 here means it should show mw level 2
                                                                        <li style={{cursor: 'pointer'}} onClick={() => getThisUserReferralsByLevel(user.id, 3, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        levelThree && levelThree.length > 0 && (
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        LEVEL 3 ({levelThreeUser})
                                                    </div>
                                                    <div className="card-body">
                                                        <ul>
                                                            {
                                                                levelThree && levelThree.length > 0 && levelThree.map(user => {
                                                                    return (
                                                                        // 2 here means it should show mw level 2
                                                                        <li style={{cursor: 'pointer'}} onClick={() => getThisUserReferralsByLevel(user.id, 5, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        levelFour && levelFour.length > 0 && (
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        LEVEL 4 ({levelFourUser})
                                                    </div>
                                                    <div className="card-body">
                                                        <ul>
                                                            {
                                                                levelFour && levelFour.length > 0 && levelFour.map(user => {
                                                                    return (
                                                                        // 2 here means it should show mw level 2
                                                                        <li style={{cursor: 'pointer'}} onClick={() => getThisUserReferralsByLevel(user.id, 3, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        levelFive && levelFive.length > 0 && (
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        LEVEL 5 ({levelFiveUser})
                                                    </div>
                                                    <div className="card-body">
                                                        <ul>
                                                            {
                                                                levelFive && levelFive.length > 0 && levelFive.map(user => {
                                                                    return (
                                                                        // 2 here means it should show mw level 2
                                                                        <li style={{cursor: 'pointer'}}>{user.firstName} {user.lastName}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <ToastContainer position="top-center" />
    </DashContainerComponent>
  )
}

export default Affiliate
