import { useMutation, useQuery } from "@tanstack/react-query"
import { logoutService } from "../services/Auth.service"
import { useSetLoaderState, useUnSetLoaderState } from "./state.hook";

export const useAuthenticatedQuery = (...options) => {
    const query = useQuery(...options)
    if (query.error?.response?.status === 401) {
        console.log(query.error?.response?.message);
        logoutService()
    }

    return query;
}

export const useAuthenticatedMutation = (...options) => {
    // useSetLoaderState()
    const query = useMutation(...options)
    if (query.error?.response?.status === 401) {
        console.log(query.error?.response?.message);
        logoutService()
    }
    // useUnSetLoaderState()

    return query;
}