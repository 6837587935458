import React, { useEffect, useState } from 'react'
import { useAdminGetUser, useAdminGetUserInvestments, useAdminGetUserTransactions, useAdminGetUserWallet, useApproveDeposit, useApproveWithDrawal, useStopUserInvestment, useAdminUserWithdrawalsService, useFundUser } from '../../hooks/admin.hook';
import { Link, useParams } from 'react-router-dom';
import * as Yup from "yup"
import DashContainerComponent from '../../components/DashContainer.component';
import { ToastContainer } from 'react-toastify';
import { formatMoney } from '../../helpers/service.helper';
import dayjs from 'dayjs'
import Logo from "../../assets/logo/lumina.png";
import { badgesOptions } from '../../config/config';
import { getUserReferrals, userTransactionService } from '../../services/Admin.service';


const schema = Yup.object({
    id: Yup.number().required("Investment ID is required").min(1).label("investment id")
})

const fundSchema = Yup.object({
    userId: Yup.number().required("User ID is required").min(1).label("User id"),
    type: Yup.string().uppercase().label("type"),
    amount: Yup.number().required().min(100).label("Amount")
})

const ViewUser = () => {
    const [user, setUser] = useState(null)
    const [userWallet, setUserWallet] = useState(null)
    const [deposits, setDeposits] = useState([])
    const [commissions, setCommissions] = useState([])
    const [withdrawals, setWithdrawals] = useState([])
    const [profits, setProfits] = useState([])
    const [investments, setInvestments] = useState([])
    const [investmentTransactions, setInvestmentTransactions] = useState([])
    const [tab, setTab] = useState("referral")
    const [users, setUsers] = useState([])

    const [body, setBody] = useState({
        amount: 0,
        type: "",
        userId: 0,
    })

    const [levelTwo, setLevelTwo] = useState([])
    const [levelTwoUser, setLevelTwoUser] = useState("");

    const [levelThree, setLevelThree] = useState([])
    const [levelThreeUser, setLevelThreeUser] = useState("");

    const [levelFour, setLevelFour] = useState([])
    const [levelFourUser, setLevelFourUser] = useState("");

    const [levelFive, setLevelFive] = useState([])
    const [levelFiveUser, setLevelFiveUser] = useState("");

    const { userId } = useParams();
    const userQuery = useAdminGetUser(userId)
    const walletQuery = useAdminGetUserWallet(userId)
    const depositQuery = useAdminGetUserTransactions(userId, "category=deposit")
    const commissionQuery = useAdminGetUserTransactions(userId, "category=commission")
    // const withdrawalQuery = useAdminGetUserTransactions(userId, "category=withdrawal")
    const withdrawalQuery = useAdminUserWithdrawalsService(userId, "category=withdrawal")
    const profitQuery = useAdminGetUserTransactions(userId, "category=profit")
    const investmentQuery = useAdminGetUserInvestments(userId, "")

    const investmentMutate = useStopUserInvestment(userId, schema)
    const approveDepositMutate = useApproveDeposit();
    const approveWithdrawalMutate = useApproveWithDrawal();
    const fundUserMutate = useFundUser(body, fundSchema);

    const  getInvestmentTransactions = async(reference) => {
        try {
            const transactionQuery = await userTransactionService(userId, `reference=${reference}&type=PCREDIT&limit=10`)
            return transactionQuery.data;
        } catch (error) {
            console.log(error);
        }
    }

    const  getUserReferralsByLevel = async(userId, level, name) => {
        try {
            if (level > 5) {
                alert("You can't go more than 5 level deep")
            }
            
            const referrals = await getUserReferrals(userId)
            if (level === 2) {
                setLevelThree([])
                setLevelTwo(referrals?.data?.referrals)
                setLevelTwoUser(name)
            }

            if (level === 3) {
                setLevelFour([])
                setLevelThree(referrals?.data?.referrals)
                setLevelThreeUser(name)
            }

            if (level === 4) {
                setLevelFive([])
                setLevelFour(referrals?.data?.referrals)
                setLevelFourUser(name)
            }

            if (level === 5) {
                setLevelFive(referrals?.data?.referrals)
                setLevelFiveUser(name)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
      setUser(userQuery?.data?.data?.user)
      setUsers(userQuery?.data?.data?.referrals)
      setUserWallet(walletQuery?.data?.data)
      setDeposits(depositQuery?.data?.data?.transactions)
      setCommissions(commissionQuery?.data?.data?.transactions)
      setWithdrawals(withdrawalQuery?.data?.data?.withdrawals)
      setProfits(profitQuery?.data?.data?.transactions)
      setInvestments(investmentQuery?.data?.data?.investments)

      setBody(prevState => ({...prevState, userId: userQuery?.data?.data?.user?.id }))
 
    }, [userQuery, walletQuery, depositQuery, commissionQuery, withdrawalQuery, profitQuery, investmentQuery])

    useEffect(() => {
        async function fetchTrans() {
            if (investments && investments.length > 0) {
                for (const investment of investments) {
                    const trans = await getInvestmentTransactions(investment.reference)
                    
                    setInvestmentTransactions(prevState => ({...prevState, [investment.reference]: trans.transactions}))
                }
            }
        }
        fetchTrans()
    }, [investments]);
    return (
        <DashContainerComponent>

            <div className="content-body">
                <div class="container">
                    <p>Name: {user?.firstName} {user?.lastName}</p>
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="icon bg-light"><i class="fa fa-bank"></i> </div>
                                    <div class="content text-light">
                                        <div class="text mb-2 text-uppercase">Balance</div>
                                        <br/>
                                        <h4 class="number mb-0">{formatMoney(userWallet?.balance || 0)} </h4>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="icon bg-light"><i class="fa fa-dollar"></i> </div>
                                    <div class="content text-light">
                                        <div class="text mb-2 text-uppercase">Total Profit</div>
                                        <br/>
                                        <h4 class="number mb-0">{formatMoney(userWallet?.totalProfit || 0)}</h4>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="icon bg-light"><i class="fa fa-credit-card-alt"></i> </div>
                                    <div class="content text-light">
                                        <div class="text mb-2 text-uppercase">Withdrawal</div>
                                        <br/>
                                        <h4 class="number mb-0">{formatMoney(userWallet?.totalWithdrawal || 0)}</h4>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="icon bg-light"><i class="fa fa-users"></i> </div>
                                    <div class="content text-light">
                                        <div class="text mb-2 text-uppercase">Total Referrals</div>
                                        <br/>
                                        <h4 class="number mb-0">{user ? user.totalReferrals : 0}</h4>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div className='row'>
                                <div className="col-12">
                                    <ul class="pagination pagination-primary">
                                        <li className={`page-item ${tab === "referral" ? "active" : ""}`} style={{"cursor": 'pointer'}}>
                                            <a class="page-link" onClick={() => setTab("referral")}>
                                                Referrals
                                            </a>
                                        </li>
                                        <li className={`page-item ${tab === "deposit" ? "active" : ""}`} style={{"cursor": 'pointer'}}>
                                            <a class="page-link" onClick={() => setTab("deposit")}>
                                                Deposit
                                            </a>
                                        </li>
                                        <li className={`page-item ${tab === "commission" ? "active" : ""}`} style={{"cursor": 'pointer'}}>
                                            <a class="page-link" onClick={() => setTab("commission")}>
                                                Commission
                                            </a>
                                        </li>
                                        <li className={`page-item ${tab === "profit" ? "active" : ""}`} style={{"cursor": 'pointer'}}>
                                            <a class="page-link" onClick={() => setTab("profit")}>
                                                Profit
                                            </a>
                                        </li>
                                        <li className={`page-item ${tab === "withdrawal" ? "active" : ""}`} style={{"cursor": 'pointer'}}>
                                            <a class="page-link" onClick={() => setTab("withdrawal")}>
                                                Withdrawals
                                            </a>
                                        </li>
                                        <li className={`page-item ${tab === "investment" ? "active" : ""}`} style={{"cursor": 'pointer'}}>
                                            <a class="page-link" onClick={() => setTab("investment")}>
                                                Investments
                                            </a>
                                        </li>
                                        <li className={`page-item ${tab === "fund" ? "active" : ""}`} style={{"cursor": 'pointer'}}>
                                            <a class="page-link" onClick={() => setTab("fund")}>
                                                Fund
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card" style={{"display": tab === "referral" ? "block" : "none"}}>
                                <div class="card-header">
                                    <h2>Referrals</h2>
                                </div>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="card">
                                                <div className="card-header">
                                                    LEVEL 1
                                                </div>
                                                <div className="card-body">
                                                    <ul>
                                                        {
                                                            users && users.length > 0 && users.map(user => {
                                                                return (
                                                                    // 2 here means it should show mw level 2
                                                                    <li style={{cursor: 'pointer'}} onClick={() => getUserReferralsByLevel(user.id, 2, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            levelTwo && levelTwo.length > 0 && (
                                                <div className="col-lg-3">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            LEVEL 2 ({levelTwoUser})
                                                        </div>
                                                        <div className="card-body">
                                                            <ul>
                                                                {
                                                                    levelTwo && levelTwo.length > 0 && levelTwo.map(user => {
                                                                        return (
                                                                            // 2 here means it should show mw level 2
                                                                            <li style={{cursor: 'pointer'}} onClick={() => getUserReferralsByLevel(user.id, 3, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            levelThree && levelThree.length > 0 && (
                                                <div className="col-lg-3">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            LEVEL 3 ({levelThreeUser})
                                                        </div>
                                                        <div className="card-body">
                                                            <ul>
                                                                {
                                                                    levelThree && levelThree.length > 0 && levelThree.map(user => {
                                                                        return (
                                                                            // 2 here means it should show mw level 2
                                                                            <li style={{cursor: 'pointer'}} onClick={() => getUserReferralsByLevel(user.id, 5, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            levelFour && levelFour.length > 0 && (
                                                <div className="col-lg-3">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            LEVEL 4 ({levelFourUser})
                                                        </div>
                                                        <div className="card-body">
                                                            <ul>
                                                                {
                                                                    levelFour && levelFour.length > 0 && levelFour.map(user => {
                                                                        return (
                                                                            // 2 here means it should show mw level 2
                                                                            <li style={{cursor: 'pointer'}} onClick={() => getUserReferralsByLevel(user.id, 3, `${user.firstName} ${user.lastName}`)}>{user.firstName} {user.lastName}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            levelFive && levelFive.length > 0 && (
                                                <div className="col-lg-3">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            LEVEL 5 ({levelFiveUser})
                                                        </div>
                                                        <div className="card-body">
                                                            <ul>
                                                                {
                                                                    levelFive && levelFive.length > 0 && levelFive.map(user => {
                                                                        return (
                                                                            // 2 here means it should show mw level 2
                                                                            <li style={{cursor: 'pointer'}}>{user.firstName} {user.lastName}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="card" style={{"display": tab === "deposit" ? "block" : "none"}}>
                                <div class="card-header">
                                    <h2>Deposits</h2>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{width: "60px"}}>#</th>
                                                    <th>Reference</th>
                                                    <th>Hash</th>
                                                    <th>Crypto</th>                                    
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    deposits && deposits.length > 0 && deposits.map(deposit => {
                                                            return (
                                                                <tr>
                                                                    <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                                    <td>{deposit.reference}</td>
                                                                    <td>{deposit.hash}</td>
                                                                    <td>{deposit.crypto}</td>
                                                                    <td><span class={badgesOptions[deposit.status.toLowerCase()]}>{deposit.status}</span></td>
                                                                    <td>{formatMoney(deposit.amount)}</td>
                                                                    <td>{dayjs(deposit.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                    <td>
                                                                        {
                                                                            deposit.status === "PENDING"
                                                                            ?
                                                                            <button className='btn btn-primary btn-lg btn-block' onClick={() => approveDepositMutate.mutate(deposit.id)}>Approve</button>
                                                                            :
                                                                            <button className='btn btn-primary btn-lg btn-block' disabled>Approved</button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style={{"display": tab === "commission" ? "block" : "none"}}>
                                <div class="card-header">
                                    <h2>Commissions</h2>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{width: "60px"}}>#</th>
                                                    <th>Reference</th>
                                                    <th>Type</th>
                                                    <th>Category</th>                                    
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    commissions && commissions.length > 0 && commissions.map(commission => {
                                                            return (
                                                                <tr>
                                                                    <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                                    <td>{commission.reference}</td>
                                                                    <td>{commission.type}</td>
                                                                    <td>{commission.category}</td>
                                                                    <td><span class={badgesOptions[commission.status.toLowerCase()]}>{commission.status}</span></td>
                                                                    <td>{formatMoney(commission.amount)}</td>
                                                                    <td>{dayjs(commission.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style={{"display": tab === "profit" ? "block" : "none"}}>
                                <div class="card-header">
                                    <h2>Profit</h2>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{width: "60px"}}>#</th>
                                                    <th>Reference</th>
                                                    <th>Type</th>
                                                    <th>Category</th>                                    
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    profits && profits.length > 0 && profits.map(profit => {
                                                            return (
                                                                <tr>
                                                                    <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                                    <td>{profit.reference}</td>
                                                                    <td>{profit.type}</td>
                                                                    <td>{profit.category}</td>
                                                                    <td><span class={badgesOptions[profit.status.toLowerCase()]}>{profit.status}</span></td>
                                                                    <td>{formatMoney(profit.amount)}</td>
                                                                    <td>{dayjs(profit.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style={{"display": tab === "withdrawal" ? "block" : "none"}}>
                                <div class="card-header">
                                    <h2>Withdrawals</h2>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{width: "60px"}}>#</th>
                                                    <th>Reference</th>
                                                    <th>Crypto</th>
                                                    <th>Wallet</th>
                                                    <th>Amount</th>
                                                    <th>Date</th>
                                                    <th>Approve</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    withdrawals && withdrawals.length > 0 && withdrawals.map(withdrawal => {
                                                            return (
                                                                <tr>
                                                                    <td><img src={Logo} width="50" height="50" alt="Product img" /></td>
                                                                    <td>{withdrawal.reference}</td>
                                                                    <td>{withdrawal.crypto}</td>
                                                                    <td>{withdrawal.wallet}</td>
                                                                    <td>{formatMoney(withdrawal.amount)}</td>
                                                                    <td>{dayjs(withdrawal.createdAt).format("MMM DD, YYYY HH:mm a")}</td>
                                                                    <td>
                                                                        {
                                                                            withdrawal.isPaid === false
                                                                            ?
                                                                            <button className='btn btn-primary btn-lg btn-block' onClick={() => approveWithdrawalMutate.mutate(withdrawal.id)}>Approve</button>
                                                                            :
                                                                            <button className='btn btn-primary btn-lg btn-block' disabled>Approved</button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card" style={{"display": tab === "investment" ? "block" : "none"}}>
                                <div class="card-header">
                                    <h2>Investments</h2>
                                </div>
                                <div class="card-body">
                                    <div className="row">
                                        {
                                            investments && investments.length > 0 && investments.map(investment =>(
                                                
                                                <div class="col-lg-4 col-md-12">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="new_timeline">
                                                                <div class="card-header">
                                                                    <div class="color-overlay">
                                                                        <div class="day-number">{dayjs(investment.createdAt).format("D")}</div>
                                                                        <div class="date-right">
                                                                            <div class="day-name">{dayjs(investment.createdAt).format("dddd")}</div>
                                                                            <div class="month">{dayjs(investment.createdAt).format("MMMM YYYY")}</div>
                                                                        </div>
                                                                    </div>                                
                                                                </div>
                                                                <ul>
                                                                    <li>
                                                                        <div class="bullet pink"></div>
                                                                        <div class="time">{dayjs(investment.createdAt).format("HH a")}</div>
                                                                        <div class="desc">
                                                                            <h3>Amount</h3>
                                                                            <h4>{formatMoney(investment.price || 0)}</h4>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="bullet pink"></div>
                                                                        <div class="time">{dayjs(investment.createdAt).format("HH a")}</div>
                                                                        <div class="desc">
                                                                            <h3>Total Profit</h3>
                                                                            <h4>{formatMoney(investment.profit)}</h4>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div class="bullet pink"></div>
                                                                        <div class="time">{dayjs(investment.createdAt).format("HH a")}</div>
                                                                        <div class="desc">
                                                                            <h3>Is Running</h3>
                                                                            <h4>{investment.isRunning.toString()}</h4>
                                                                        </div>
                                                                    </li>
                                                                    {/* {
                                                                        investmentTransactions && investmentTransactions[investment.reference] && investmentTransactions[investment.reference].length > 0 && investmentTransactions[investment.reference].map(trans => (
                                                                            <li>
                                                                                <div class="bullet pink"></div>
                                                                                <div class="time">{dayjs(trans.createdAt).format("DD/MM/YY HH:mm a")}</div>
                                                                                <div class="desc">
                                                                                    <h3>Profit Amount</h3>
                                                                                    <h4>{formatMoney(trans.amount || 0)}</h4>
                                                                                </div>
                                                                            </li>
                                                                        ))
                                                                    } */}
                                                                </ul>
                                                                
                                                            </div>
                                                            <br/>
                                                            {
                                                                investment.isRunning
                                                                ?
                                                                // !loginMutation.isPending 
                                                                // ?
                                                                // <button type="submit" class="btn btn-primary btn-lg btn-block">
                                                                //     LOGIN
                                                                // </button>
                                                                // :
                                                                // <button disabled={true} class="btn btn-primary btn-lg btn-block">
                                                                //     <div class="spinner-border" role="status">
                                                                //     <span class="sr-only">Loading...</span>
                                                                //     </div>
                                                                // </button>
                                                                
                                                                <button disabled={investmentMutate.isPending} class="btn btn-outline-danger" onClick={(e) => investmentMutate.mutate(investment.id)}>
                                                                    {
                                                                        investmentMutate.isPending
                                                                        ?
                                                                        <div class="spinner-border" role="status">
                                                                          <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                        :
                                                                        <>STOP</>
                                                                    }
                                                                </button>
                                                                :
                                                                <button class="btn btn-outline-primary" disabled>
                                                                    STOPPED
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div class="card" style={{"display": tab === "fund" ? "block" : "none"}}>
                                <div class="card-header">
                                    <h2>Fund ({user?.firstName +" "+ user?.lastName})</h2>
                                </div>
                                <div class="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <form onSubmit={(e) => fundUserMutate.mutate(e)}>
                                                <div className="form-group mb-2">
                                                    <select className="form-control" onChange={(e) => setBody(prevState => ({...prevState, type: e.target.value}))}>
                                                        <option>select type...</option>
                                                        <option value="CREDIT">CREDIT</option>
                                                        <option value="DEBIT">DEBIT</option>
                                                    </select>
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="number" onChange={(e) => setBody(prevState => ({...prevState, amount: parseInt(e.target.value)}))} placeholder="enter amount" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <button disabled={fundUserMutate?.isPending} className="btn btn-primary">Fund</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        <ToastContainer position="top-center" />
        </DashContainerComponent>
        
    )
}

export default ViewUser