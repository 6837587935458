import React, { useState } from "react";
import * as Yup from "yup"
import AuthContainerComponent from "../../components/AuthContainer.component";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/luminatrust.png"
import { useLogin } from "../../hooks/auth.hook";
import { ToastContainer } from "react-toastify";
import PrimaryButtonComponent from '../../components/PrimaryButton.component'


const schema = Yup.object({
  email: Yup.string().email("invalid email address").lowercase().required("email is required").label("email"),
  password: Yup.string().min(8, "password must be at least 8 characters").required("password is required").label("password")
})

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [body, setBody] = useState({
    email: "",
    password: ""
  })

  const loginMutation = useLogin(body, schema)

  return (
    <AuthContainerComponent>
      <div class="col-lg-6">
        <div class="auth-form">
          <h4>Sign In</h4>
          <form onSubmit={(e) => loginMutation.mutate(e)}>
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-label">Email</label>
                <input name="email" onChange={(e) => setBody(prevState => ({...prevState, email: e.target.value}))} type="text" class="form-control" />
              </div>
              <div class="col-12 mb-3">
                <label class="form-label">Password</label>
                <input name="password" onChange={(e) => setBody(prevState => ({...prevState, password: e.target.value}))} type="password" class="form-control" />
              </div>
              <div class="col-6">
                <div class="form-check">
                  <input
                    name="acceptTerms"
                    id="acceptTerms"
                    type="checkbox"
                    class="form-check-input"
                  />
                  <label class="form-check-label" for="acceptTerms">
                    Remember me
                  </label>
                </div>
              </div>
              <div class="col-6 text-end">
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
            </div>
            <div class="mt-3 d-grid gap-2">
              <PrimaryButtonComponent title="LOGIN" isPending={loginMutation.isPending} />
            </div>
          </form>
          <p class="mt-3 mb-0 undefined">
            Don't have an account?
            <Link class="text-primary" to="/register">
              {" "}
              Sign up
            </Link>
          </p>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </AuthContainerComponent>
    // <AuthContainerComponent>
    //   <div class="card">
    //     <div class="header">
    //       <div class="top">
    //           <img src={Logo} alt="Lumina trust" />
    //       </div>
    //       <p class="lead">Login to your account</p>
    //     </div>
    //     <div class="body">
    //       <form onSubmit={(e) => loginMutation.mutate(e)} class="form-auth-small">
    //         <div class="form-group">
    //           <label for="signin-email" class="control-label sr-only">
    //             Email
    //           </label>
    //           <input
    //             type="email"
    //             class="form-control"
    //             id="signin-email"
    //             onChange={(e) => setBody(prevState => ({...prevState, email: e.target.value}))}
    //             placeholder="Email"
    //           />
    //         </div>
    //         <div class="form-group">
    //           <label for="signin-password" class="control-label sr-only">
    //             Password
    //           </label>
    //           <input
    //             type="password"
    //             class="form-control"
    //             id="signin-password"
    //             onChange={(e) => setBody(prevState => ({...prevState, password: e.target.value}))}
    //             placeholder="Password"
    //           />
    //         </div>
    //         <div class="form-group clearfix">
    //           <label class="fancy-checkbox element-left">
    //             <input type="checkbox" />
    //             <span>Remember me</span>
    //           </label>
    //         </div>
    //         {
    //           !loginMutation.isPending
    //           ?
    //           <button type="submit" class="btn btn-primary btn-lg btn-block">
    //             LOGIN
    //           </button>
    //           :
    //           <button disabled={true} class="btn btn-primary btn-lg btn-block">
    //             <div class="spinner-border" role="status">
    //               <span class="sr-only">Loading...</span>
    //             </div>
    //           </button>
    //         }

    //         <div class="bottom">
    //           <span class="helper-text m-b-10">
    //             <i class="fa fa-lock"></i>{" "}
    //             <Link to="/forgot-password">Forgot password?</Link>
    //           </span>
    //           <span>
    //             Don't have an account? <Link to="/register">Register</Link>
    //           </span>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    //   <ToastContainer position="top-center" />
    // </AuthContainerComponent>
  );
};

export default Login;
